import QuizContainer from "../../../components/Quiz/QuizContainer";
import quizBgMaster from "../../../assets/img/QUIZ-Connect-Grafik_QC-Screen-Smartphone.png";
import Countdown from "react-countdown";
import React from "react";
import renderer from "./AnswerViewCountdownRenderer";

export default function QuizMasterAnswerView(props){

    const isMaster = props.isMaster
    const hasMaster = props.hasMaster

    return(
        <QuizContainer
            background={quizBgMaster}
        >
            <Countdown
                onComplete={props.timerDidEnd}
                date={props.timerEndTime}
                renderer={({ hours, minutes, seconds, completed })=> renderer({ hasMaster, isMaster, hours, minutes, seconds, completed} ) } />
                <div style={{width:"80%", margin:"0 auto"}}>
                <h1 className={"quiz-bold quiz-header"} style={{fontSize:"5em", textAlign:"center", width:"100%"}}>
                    Gib die Antwort mit deinem Handy
                </h1>
            </div>

        </QuizContainer>
    )
}
