import React, {useState, useEffect} from 'react'

import {Link} from 'react-router-dom'; // IF IS REACT-WEB
import {makeStyles} from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";

import fetcher from "services/Fetcher.js"
import {QueryBuilder, TextFields} from "@material-ui/icons";
import moment from "moment";

const useStyles = makeStyles(styles);

export default function Events() {

    const url = "Event/"
    const [events, setEvents] = useState([])

    useEffect(() => {

        fetcher(url)
            .then(resp => resp.json() )
            .then((data) => {
                setEvents(data)
            })

        const interval = setInterval(()=>{
            fetcher(url)
                .then(resp => resp.json() )
                .then((data) => {
                    setEvents(data)
                })
        },3000)

        return ()=>{
            clearInterval(interval)
        }
    }, [])

    const classes = useStyles()

    var full = window.location.protocol+'//'+window.location.hostname+(window.location.port ? ':'+window.location.port: '');

    const forceOpenLobby = (event) => {
        if(window.confirm("Lobby manuell öffnen? Event startet 5 Minuten später!")){
            fetcher("Event/" + event.id + "/lobby", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then(r => r.json())
                .then((e) => {
                    setEvents(e)
                })
        }
    }

    const forceStartEvent = (event) => {
        if(window.confirm("Event manuel starten? Wir starten in 5 Sekunden!")) {
            fetcher("Event/" + event.id + "/force", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then(r => r.json())
                .then((e) => {
                    setEvents(e)
                })
        }
    }

    const stopQuiz = (e) => {

        if(!window.confirm("Quiz beenden?")) return

        fetcher("Event/" + e.id + "/stop", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then(r => r.json())
            .then((e) => {
                setEvents(e)
            })
    }

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="primary">
                        <h4>Geplante Events</h4>
                    </CardHeader>
                    <CardBody>
                        <Table>
                            <TableHead>
                                <TableRow className={classes.tableHeadRow}>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Lobby Start / Event Start</TableCell>
                                    <TableCell>Aktionen</TableCell>
                                    <TableCell>Bearbeiten</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {events.map((data, key) => {
                                    return (
                                        <TableRow key={key}>
                                            <TableCell>
                                                {data.status === "pending" &&
                                                    <QueryBuilder />
                                                }
                                                {data.status !== "pending" &&
                                                    data.status
                                                }
                                            </TableCell>
                                            <TableCell>{data.name}</TableCell>
                                            <TableCell>
                                                <b>Lobby Start:</b> <br /> { moment(data.lobbyStart).format("DD.MM.YYYY HH:mm")}<br />
                                                <b>Event Start:</b><br /> { moment(data.eventStart).format("DD.MM.YYYY HH:mm")}
                                            </TableCell>
                                            <TableCell>
                                                <Link to={"/admin/events/control/" + data.id}>
                                                    <Button size={"sm"} style={{width:"80%"}} color="danger" >
                                                        Mission Control
                                                    </Button>
                                                </Link>
                                            </TableCell>
                                            <TableCell>
                                                { data.isRunning == false &&
                                                <div>
                                                    <Link to={"/admin/EventEdit/" + data.id}>
                                                        <Button  style={{width:"50%"}} color="primary">
                                                            Bearbeiten
                                                        </Button>
                                                    </Link>
                                                    <Link to={"/admin/EventDelete/" + data.id}>
                                                        <Button  style={{width:"50%"}} color="warning">
                                                            Löschen
                                                        </Button>
                                                    </Link>
                                                </div>
                                                }
                                                {data.isRunning == true &&
                                                    <div>
                                                        <Button size={"sm"} style={{width: "50%"}} color="info"
                                                                onClick={() => stopQuiz(data)}>
                                                            Quiz beenden
                                                        </Button>
                                                        <Link to={"/admin/EventEdit/" + data.id}>
                                                            <Button size={"sm"} style={{width:"50%"}} color="primary">
                                                                Bearbeiten
                                                            </Button>
                                                        </Link>
                                                    </div>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>

                    </CardBody>
                    <CardFooter>
                        <Link to="/admin/EventEdit/new">
                            <Button color="primary">
                                Neues Event anlegen
                            </Button>
                        </Link>
                    </CardFooter>
                </Card>
            </GridItem>
        </GridContainer>
    );
}
