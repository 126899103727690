import QuizContainer from "../../../components/Quiz/QuizContainer";
import React from "react";
import bg from "../../../assets/img/QUIZ-Connect-Grafik_QC-Screen-BG-Fehler.png";

export default function QuizMasterScore(props){

    return(
        <QuizContainer background={bg} >
            <div id="finisher" className="center quizMasterScore winner_warpper" >

                <div className={"quizMasterScore-winner_header"}>
                    <span>Sieger</span>
                </div>
                {props.result &&
                    <div className={"winner-list"}>
                        {props.result.winners.length >= 2 &&
                        <div className={"winner winner_2"} >
                            <img src={props.result.winners[1].avatar} alt={"avatar"} className={"winner-avataor"}/>
                            <div className={"info_winner_box info_winner_box_2"}>
                                <span className={"winner-placement"} style={{fontSize:"1.8em"}} >2.</span>
                                <span className={"winner-name"}  >{props.result.winners[1].name}</span>
                                <span className={"winner-score"}  >Mit {props.result.winners[1].score} richtigen Antworten</span>
                                <span className={"winner-avarage"}  >{props.result.winners[1].averageTime / 1000} Sek. &#x00D8;</span>
                            </div>
                        </div>
                        }

                        {props.result.winners.length >= 1 &&
                        <div className={"winner winner_1"} >
                            <img src={props.result.winners[0].avatar} alt={"avatar"} className={"winner-avataor"}/>
                            <div className={"info_winner_box info_winner_box_1"}>
                                <span className={"winner-placement df"} style={{fontSize:"1.8em"}}>1.</span>
                                <span className={"winner-name"} >{props.result.winners[0].name}</span>
                                <span className={"winner-score"} >Mit {props.result.winners[0].score} richtigen Antworten</span>
                                <span
                                    className={"winner-avarage"} >{props.result.winners[0].averageTime / 1000} Sek. &#x00D8;</span>
                            </div>
                        </div>
                        }
                        {props.result.winners.length >= 3 &&
                            <div className={"winner winner_3"} >
                                <img src={props.result.winners[2].avatar} alt={"avatar"} className={"winner-avataor"}/>
                                <div className={"info_winner_box info_winner_box_3"}>
                                    <span className={"winner-placement"} style={{fontSize:"1.8em"}} >3.</span>
                                    <span className={"winner-name"} >{props.result.winners[2].name}</span>
                                    <span className={"winner-score"} >Mit {props.result.winners[2].score} richtigen Antworten</span>
                                    <span
                                        className={"winner-avarage"} >{props.result.winners[2].averageTime / 1000} Sek. &#x00D8;</span>
                                </div>
                            </div>
                        }
                    </div>
                }

                <div style={{margin:"1vh auto", textAlign:"center"}}>
                    <img className={"companyBrand"} src={props.eventLogo} />
                </div>
            </div>
        </QuizContainer>
    )
}
