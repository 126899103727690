/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";

import QuestionAnswer from "@material-ui/icons/QuestionAnswer";
import Event from "@material-ui/icons/Event";
import DashboardPage from "views/Dashboard/Dashboard.js";
import Questions from "views/Questions/Questions.js";
import QuestionEdit from "views/Questions/QuestionEdit.js";
import QuestionDelete from "views/Questions/QuestionDelete.js";

import Events from "views/Events/Events.js";
import EventEdit from "views/Events/EventEdit.js";
import EventDelete from "views/Events/EventDelete.js";

import Login from "views/Account/Login.js";

import Quiz from "views/Play/Quiz.js";

import UserEdit from "views/Account/UsersEdit";
import Users from "views/Account/Users";
import Sounds from "./views/Sounds/Sounds";
import {ChildCare, EmojiFlags, VolumeUp} from "@material-ui/icons";
import SoundAdd from "./views/Sounds/SoundAdd";
import Results from "./views/Results/Results";
import ResultDetails from "./views/Results/ResultDetails";
import AvatarList from "./views/Avatar/AvatarList";
import AvatarEditor from "./views/Avatar/AvatarEditor";
import Logout from "./views/Logout";
import MissionControl from "./views/Events/MissionControl";


const dashboardRoutes = [

    {
        path: "/quiz/:eventId",
        name: "Quiz",
        icon: Dashboard,
        component:  Quiz,
        layout: "/playground",
        invisible: true
    },
    {
        path: "/quiz",
        name: "Quiz",
        icon: Dashboard,
        component:  Quiz,
        layout: "/playground",
        invisible: true
    },
    {
        path: "/login",
        name: "Login",
        icon: Dashboard,
        component:  Login,
        layout: "/login",
        invisible: true
    },

    /*
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: Dashboard,
        component: DashboardPage,
        layout: "/admin"
    }, */
    {
        path: "/events/control/:eventId",
        name: "MissionControl",
        icon: Event,
        component: MissionControl,
        layout: "/admin",
        invisible: true
    },
    {
        path: "/events",
        name: "Events",
        icon: Event,
        component: Events,
        layout: "/admin"
    },
    {
        path: "/eventEdit/:eventId",
        name: "Events Bearbeitung",
        icon: Event,
        component: EventEdit,
        layout: "/admin",
        invisible: true
    },

    {
        path: "/eventDelete/:eventId",
        name: "Event löschen",
        icon: Event,
        component: EventDelete,
        layout: "/admin",
        invisible: true
    },
    {
        path: "/results/:id",
        name: "Ergebnisse",
        icon: EmojiFlags,
        component: ResultDetails,
        layout: "/admin",
        invisible: true
    },
    {
        path: "/results",
        name: "Ergebnisse",
        icon: EmojiFlags,
        component: Results,
        layout: "/admin"
    },
    {
        path: "/questions/:directoryId",
        name: "Fragenkatalog",
        icon: QuestionAnswer,
        component: Questions,
        layout: "/admin",
        invisible: true
    },
    {
        path: "/questions/",
        name: "Fragenkatalog",
        icon: QuestionAnswer,
        component: Questions,
        layout: "/admin"
    },
    {
        path: "/questionDelete/:questionId",
        name: "Frage löschen",
        icon: QuestionAnswer,
        component: QuestionDelete,
        layout: "/admin",
        invisible: true
    },
    {
        path: "/QuestionEdit/:questionId",
        name: "Frage bearbeiten",
        icon: QuestionAnswer,
        component: QuestionEdit,
        layout: "/admin",
        invisible: true
    },
    {
        path: "/avatar/add",
        name: "Avatare add",
        icon: QuestionAnswer,
        component: AvatarEditor,
        layout: "/admin",
        invisible: true
    },
    {
        path: "/avatars",
        name: "Avatare",
        icon: ChildCare,
        component: AvatarList,
        layout: "/admin",
    },
    {
        path: "/sounds/add",
        name: "Sounds add",
        icon: QuestionAnswer,
        component: SoundAdd,
        layout: "/admin",
        invisible: true
    },
    {
        path: "/sounds",
        name: "Sounds",
        icon: VolumeUp,
        component: Sounds,
        layout: "/admin"
    },

    {
        path: "/users",
        name: "Benutzerverwaltung",
        icon: Person,
        component: Users,
        layout: "/admin"
    },
    {
        path: "/usersEdit/:userId",
        name: "Benutzer bearbeiten",
        icon: Person,
        component: UserEdit,
        layout: "/admin",
        invisible: true
    },
    {
        path: "/logout",
        name: "Logout",
        icon: Dashboard,
        component:  Logout,
        layout: "/admin"
    },
];

export default dashboardRoutes;
