import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'; // IF IS REACT-WEB
import {makeStyles} from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import Button from "components/CustomButtons/Button.js";

import fetcher from "services/Fetcher.js"
import Redirect from "react-router-dom/Redirect";

import QuestionBrowser from "../../components/Question/QuestionBrowser";

export default function Questions(props) {

    const questionUrl = "Question/"
    const directoryUrl = "Directory/"
    const [questions, setQuestions] = useState([])
    const [directories, setDirectories] = useState([])

    const [currentDirectory, setCurrentDirectory] = useState(null)
    const [redirect , setRedirect] = useState("")

    const [directory, setDirectory] = useState("")
    useEffect(()=>{
        const queryParams = new URLSearchParams(window.location.search);
        let directory = queryParams.get('directory');
        if(!directory) directory = ""
        setDirectory(directory)
    },[])

    const loadCurrentDirectory = ()=>{
        if(directory){
            fetcher(directoryUrl + directory)
                .then(resp => resp.json())
                .then((data) => {
                    console.log("loadCurrentDirectory", data)
                    setCurrentDirectory(data)
                })
        }else{
            setCurrentDirectory(null)
        }
    }
    useEffect(loadCurrentDirectory, [directory])

    const loadDirectories = ()=>{
        fetcher(directoryUrl + "?parentId=" + directory)
            .then(resp => resp.json())
            .then((data) => {
                console.log("loadDirectories", data)
                setDirectories(data)
            })
    }
    useEffect(loadDirectories, [directory])

    const loadQuestions = () => {
        fetcher(questionUrl + "?directoryId=" + directory)
            .then(resp => resp.json())
            .then((data) => {
                setQuestions(data)
            })
    }

    useEffect(loadQuestions, [directory])

    const questionDelete = (qid) => {
        if(window.confirm("Frage wirklich löschen? Passiert nur, wenn sie nicht mehr verwendet wird!")){
            fetcher("question/" + qid, {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then(r => r.json())
                .then(r => setQuestions(r) )
        }
    }

    function newDirectory() {
        const directoryName = prompt("Verzeichnis Name:")
        if(directoryName){
            fetcher(directoryUrl, {
                method: "POST",
                body: JSON.stringify({name: directoryName, parent: directory}),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then(r => r.json())
                .then(r => {
                    setRedirect("/admin/questions/" + r.id)
                } )
        }
    }

    if(redirect){
       return  <Redirect to={redirect} />
    }

    function handleErrors(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    }

    function deleteFolder(id) {
        if(!window.confirm("Ist der Ordner leer? Soll er wirklich gelöscht werden?")) return

        fetcher(directoryUrl + id, {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then(handleErrors)
            .then( loadDirectories )
            .catch(err => window.alert("Ordner konnte nicht gelöscht werden!"))
    }

    const moveQuestion = (questionId, moveToId) => {
        let question = questions.find(q => q.id == questionId)
        question.directory = moveToId

        console.log(question)

        if(window.confirm("Frage verschieben?") == false )return

        fetcher(questionUrl + question.id , {
            method: "PUT",
            body: JSON.stringify(question),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then( loadQuestions )
    }

    const directoryChange = (directory)=>{
        setDirectory(directory)
    }

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="primary">
                        <h4>Fragen {currentDirectory && <span> aus {currentDirectory.name}</span>}</h4>
                    </CardHeader>
                    <CardBody>
                        <QuestionBrowser
                            canEdit={true}
                            directoryChange={directoryChange}
                            directories={directories}
                            currentDirectory={currentDirectory}
                            deleteFolder={deleteFolder}
                            questions={questions}
                            directory={directory}
                            questionDelete={questionDelete}
                            moveQuestion={moveQuestion}
                            from={"/admin/questions/"}
                        />
                    </CardBody>
                    <CardFooter>
                        <Link to={"/admin/QuestionEdit/new" + "?directory=" + directory} >
                            <Button color="primary">
                                Neue Frage anlegen
                            </Button>
                        </Link>
                        <Button color="info" onClick={newDirectory}>
                            Neues Verzeichnis anlegen
                        </Button>
                    </CardFooter>
                </Card>
            </GridItem>
        </GridContainer>
    );
}
