import React, {useState} from "react";

import QuizContainer from "../../components/Quiz/QuizContainer";
import defaultBg from "../../assets/img/QUIZ-Connect-Grafik_QC-Screen-BG-standard.png";

import NoSleep from 'nosleep.js';
import TextField from "@material-ui/core/TextField";

export default function QuizMasterJoin(props) {
    const [masterPassword, setMasterPassword] = useState("")
    const join = (e) => {

        const noSleep = new NoSleep();
        noSleep.enable().then(()=>{
            console.log("i will NEVER sleep")
        })

        document.getElementById("videoPlayer").muted = false;
        document.getElementById("audioPlayerBg").muted = false;
        document.getElementById("audioPlayerComment").muted = false;

        props.register(e, masterPassword)
    }

    return(
        <QuizContainer
            background={defaultBg}
            eventLogo={props.eventLogo} >

            <div style={{textAlign: "center"}}>
                <h1 className={"quiz-bold quiz-header"}>
                    Spielleiter Ansicht!
                </h1>
                <TextField label={"Passwort"} style={{
                    width: "20vw",
                    minWidth: "100px",
                    minHeight: "40px"
                }}
                                margin={"normal"} name={"name"} type={"password"} value={masterPassword} onChange={ e => setMasterPassword(e.target.value) }/>
                <button
                    className={"quiz-button"}
                    onClick={ (e) => { e.target.disabled = true ; join(e) } }>
                    OK
                </button>
            </div>

        </QuizContainer>
    )
}
