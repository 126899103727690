import bg from "../../../assets/img/QUIZ-Connect-Grafik_QC-Screen-BG-Fehler.png";
import QuizContainer from "../../../components/Quiz/QuizContainer";
import React from "react";

export default function QuizMemberScore(props) {
    return (
        <QuizContainer background={bg}>
            <div id={"finisher_countdown"}>
                <span>{props.countdownTimer}</span>
            </div>

            <div className={"private-avatar"}>
                <img src={props.result.privateAvatar} alt={"my avatar"}/>
            </div>

            <div className={"winner-private-score-wrapper"}>
                <div className={"winner-private-score"}>
                    <span>Dein Rang: {props.result.placement}</span>
                    <p style={{fontSize: "0.6em"}}>Mit {props.result.privateScore} richtigen Antworten</p>
                </div>
            </div>


            <div style={{margin:"1vh auto", textAlign:"center"}}>
                <img className={"companyBrand"} src={props.eventLogo} />
            </div>
        </QuizContainer>
    )
}
