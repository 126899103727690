import React from "react";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import QuizContainer from "../../components/Quiz/QuizContainer";
import defaultBg from "../../assets/img/QUIZ-Connect-Grafik_QC-Screen-BG-standard.png";
import quizConnectLogo from "../../assets/img/QUIZ-Connect-Grafik_Quiz-Connect-Logo.png";

export default function MemberTooLate(props) {

    return(
        <QuizContainer
            background={defaultBg}
        >
            <div style={{textAlign:"center"}} >
                <p style={{
                    margin: "0",
                    fontSize:"1.8em",
                    color:"#fff",
                    fontFamily:"Quicksand",
                    fontWeight:"700"
                }}>
                    Sorry<br/>
                    Das Quiz hat ohne<br />
                    Dich angefangen.
                </p>
            </div>
        </QuizContainer>

    )
}