import React, {useState} from "react";
import {useEffect} from "react";
import fetcher from "../../services/Fetcher";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import Button from "../../components/CustomButtons/Button";
import CardFooter from "../../components/Card/CardFooter";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import {Link} from "react-router-dom";
import CardHeader from "../../components/Card/CardHeader";

export default function AvatarList(props){
    const [avatars, setAvatars] = useState([]);

    useEffect(()=>{
        fetcher("avatar")
            .then(r => r.json())
            .then(r => setAvatars(r) )
    },[])

    const deleteAvatar = (avatar) => {
        if(window.confirm("Avatar wirklich löschen?")){
            fetcher("avatar/" + avatar.id, {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then(r => r.json())
                .then(r => setAvatars(r))
        }
    }

    const createRows = ()=>{
        return avatars.map((value, index) => {
            return(
                <TableRow key={ "avatar_" + index }>
                    <TableCell>
                        {value.name}
                    </TableCell>
                    <TableCell>
                        <img style={{width:"100px"}} src={value.path} />
                    </TableCell>
                    <TableCell>
                        {value.createdAt}
                    </TableCell>
                    <TableCell>
                        <Button onClick={ () => deleteAvatar(value) } color={"warning"} > x </Button>
                    </TableCell>
                </TableRow>
            )
        })
    }

    return(
        <Card>
            <CardHeader>
                <h1>Avatare</h1>
            </CardHeader>
            <CardBody>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Name
                            </TableCell>
                            <TableCell>
                                Bild
                            </TableCell>
                            <TableCell>
                                Datum
                            </TableCell>
                            <TableCell>
                                Löschen
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {createRows()}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell>
                                <Link to={"/admin/avatar/add"}>
                                    <Button color={"primary"}>Neuer Avatar</Button>
                                </Link>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </CardBody>
        </Card>
    )

}
