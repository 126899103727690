import React,{useEffect, useState}from "react";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Countdown from "react-countdown";
import moment from "moment";
import defaultBg from "assets/img/QUIZ-Connect-Grafik_QC-Screen-BG-standard.png"
import Container from "@material-ui/core/Container";

import eventLogo from "assets/img/reactlogo.png"

import quizConnectLogo from "assets/img/QUIZ-Connect-Grafik_Quiz-Connect-Logo.png"
import QuizContainer from "../../components/Quiz/QuizContainer";

export default function EventPending(props) {

    console.log(props)
    const [eventStart, setEventStart] = useState("")
    const [eventStartTime, setEventStartTime] = useState(null)

    const [days, setDays] = useState(0)

    useEffect( () => {

        let lobbyStart = props.properties.find( (x) => x.key === "lobbyStartTimeStamp");
        console.log("props",props)
        if(lobbyStart){
            const delta = Date.now() - props.serverTimeMillis;
            const time = lobbyStart.value + delta

            setEventStartTime(time)
            setEventStart(lobbyStart.value)

            var a = moment(lobbyStart.value);
            var b = moment();
            setDays( a.diff(b, 'days'))
        }
    },[]);

    const renderer = ({ hours, minutes, seconds, completed }) => {
        hours = hours + (days * 24)
        return <p style={{
            fontSize:"1.5em",
            color:"white",
            fontWeight:"700"
        }}>

            {hours > 0 &&
                <span>{hours} Stunden - </span>
            }
            {minutes > 0 &&
            <span>{minutes} Minuten - </span>
            }
            {seconds} Sekunden
        </p>;
    };

    if(!eventStartTime){
        return (<div></div>)
    }

    const timerEnd = ()=>{
        window.location.reload();
    }

    return(
        <QuizContainer background={defaultBg} >
            <div className={"center"} style={{width:"100%", margin:"0 auto"}}>
                <img className={"companyBrand"} src={props.eventLogo} />
            </div>
            <div style={{margin:"0 auto", textAlign:"center", color:"#fff", fontSize:"1.5em"}}>
                <p style={{fontWeight:"700",fontSize:"1em",lineHeight: "1.1em"}}>
                    Start {moment(eventStart).format("D.MM.yy HH:mm")}</p>
                {eventStartTime != null && eventStartTime != null > 0 &&
                    <Countdown date={ eventStartTime  }  renderer={renderer}  onComplete={timerEnd} />
                }
            </div>

        </QuizContainer>
    )
}
