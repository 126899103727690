import React, {useEffect, useState} from 'react'
import GridItem from "../../components/Grid/GridItem";
import QuizContainer from "../../components/Quiz/QuizContainer";
import quizBg from "../../assets/img/QUIZ-Connect-Grafik_QC-Screen-BG-Quiz.png";
import Countdown from "react-countdown";
import AvatarSelector from "./AvatarSelector";
import PlayerNameSelector from "./PlayerNameSelector";
import QuizMasterLobby from "./QuizMaster/QuizMasterLobby";
import checkImage from "../../services/ImageCache";
import fetcher from "../../services/Fetcher";

export default function Lobby(props) {
    const [ playerName, setPlayerName ] = useState("");
    const [ newPlayerName, setNewPlayerName ] = useState("");
    const [eventStart, setEventStart] = useState(0)
    const [animate, setAnimate] = useState(false)
    const [changeNameAction, setChangeNameAction] = useState(props.eventStore.name === "")
    const [avatarSelection, setAvatarSelection] = useState(0)
    const [changeAvatarAction, setChangeAvatarAction] = useState(props.eventStore.avatar === "")
    const [timerEnds, setTimerEnds] = useState(false)
    const [selectedAvatar, setSelectedAvatar] = useState({path:""})
    const [userList, setUserList]  = useState([])
    const [userCount, setUserCount] = useState(0)

    const avatars = props.result.avatarList;

    const [imageLoaded,setImageLoaded] = useState(false)

    const loadUserList = ()=>{
        fetcher("quiz/event/" + props.eventId +"/users")
            .then(resp => resp.json())
            .then( (list) => {
                if(userCount == list.count) return

                setUserList(list.members)
                setUserCount(list.count)
            });
    }
    useEffect( () => {
        loadUserList()
        const interval = setInterval(()=>{
            loadUserList()
        },3000)
        return ()=> clearInterval(interval)
    })

    useEffect(()=>{
        let bgSound = "/sounds/silence.mp3"
        let currentTime = 0;
        if(props.isMaster || props.hasMaster == false) {
            bgSound = "/sounds/lobby_music.mp3"
        }else{
             currentTime = localStorage.getItem("lobby_bg");
            if(!currentTime){
                currentTime = 0;
            }
        }

        const lobbyAudio = document.getElementById("audioPlayerBg");
        lobbyAudio.src = bgSound;
        lobbyAudio.volume=0.1;
        lobbyAudio.loop = true;
        lobbyAudio.onended =  function(){
            lobbyAudio.currentTime = 0;
       };
        lobbyAudio.load();
        lobbyAudio.pause();
        lobbyAudio.currentTime = currentTime;
        let loadedMetadata;
        loadedMetadata = function(event){
            lobbyAudio.currentTime = currentTime;
            lobbyAudio.removeEventListener("loadedmetadata", loadedMetadata);
        }
        if(lobbyAudio.currentTime !== currentTime){
            lobbyAudio.addEventListener("loadedmetadata", loadedMetadata);
        }
        lobbyAudio.play();

        const interval = setInterval(function(){localStorage.setItem("lobby_bg", document.getElementById("audioPlayerBg").currentTime)},1000)

        return function (){
            if(interval != null){
                clearInterval(interval);
            }
            lobbyAudio.onended = function(){ };
            lobbyAudio.volume = 0.5;
            lobbyAudio.loop = false;
            lobbyAudio.currentTime = 0;
            lobbyAudio.pause();
        }
    },[])

    useEffect(() => {
        setPlayerName(props.eventStore.name + "")
        setNewPlayerName(props.eventStore.name + "");

        const delta = Date.now() - props.serverTimeMillis;

        console.log("eventStartTimeStamp",props.result.eventStartTimeStamp)
        if(props.result.eventStartTimeStamp){
            const eventTime = ( props.result.eventStartTimeStamp ) + delta
            setEventStart(eventTime)
        }
    },[])

    useEffect(() => {
       let eventStore = localStorage.getItem("eventStore")
        eventStore = JSON.parse(eventStore)
        console.log("eventStore", eventStore)
        if(eventStore.avatar){
            setSelectedAvatar(eventStore.avatar)
        }
    },[])

    const savePlayerName = (name)=>{
        setChangeNameAction(false)
        setPlayerName(name);
        props.onNameChanged(name);
        setAnimate(true)
        setTimeout(()=>{
            setAnimate(false)
        },1000)
    }

    const saveAvatar = (avatarIndex) =>{
        setChangeAvatarAction(false)
        setAvatarSelection(avatarIndex)
        setSelectedAvatar(avatars[avatarIndex])
        props.onAvatarChanged(avatars[avatarIndex]);
    }

    if(props.eventStore.isQuizMaster){
        return (
            <QuizMasterLobby
                eventStart = {eventStart}
                eventLogo = { props.eventLogo }
                store={props.eventStore}
            />
        )
    }

    if(changeNameAction){
        return (
            <PlayerNameSelector
                eventLogo = { props.eventLogo }
                savePlayerName = {savePlayerName}
            />
        )
    }

    if(changeAvatarAction){
        return (
            <AvatarSelector
                avatars={avatars}
                eventLogo={props.eventLogo}
                saveAvatar={saveAvatar}
            />
        )
    }

    const renderer = ({ hours, minutes, seconds, completed }) => {
        return (
            <p style={{
                margin:"0",
                fontSize:"1.5em",
                color:"white",
                fontWeight:"700"
            }}>
                {completed}
                {hours > 0 &&
                <span>{hours} Stunden - </span>
                }
                {minutes > 0 &&
                <span>{minutes} Minuten - </span>
                }
                {seconds} Sekunden
            </p>
        );
    };

    const timerEnd = ()=>{}

    return (
        <QuizContainer  background={quizBg}  >

            {timerEnds &&
                <GridItem xs={12} sm={12} md={12} className={"center"}>
                    <button
                        className={"nameChangeButton"}
                        type={"button"}
                        onClick={ ()=> window.location.reload() }>
                        Los geht's!
                    </button>
                </GridItem>
            }

            <div className={"center"} style={{margin:"0"}}>
                <div><img className="lobby_avatar" src={ selectedAvatar.path } /></div>
                <span style={{fontSize:"2em", fontWeight:"bold"}} className="playerName">{playerName}</span>
            </div>
            <div className={"center"}>
                <h1 className={"quiz-header"}>Gleich geht's los!</h1>
            </div>

            <div xs={12} style={{margin:"0 auto", textAlign:"center"}}>
                {eventStart &&
                    <Countdown date={ eventStart }  renderer={renderer}  onComplete={timerEnd} />
                }
            </div>

            <div style={{width: "50%",margin:"0 auto", textAlign:"center"}}>
                <ul className={"member-list"}>
                    {userList.map((user,index)=>
                        <li key={"user_" + index}>{user.name}</li>
                    )}
                </ul>
                <span style={{fontWeight:"bold"}}>{userCount} Kandidaten</span>
            </div>

        </QuizContainer>
    );
}
