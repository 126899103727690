import React, {useState} from "react";
import QuizContainer from "../../components/Quiz/QuizContainer";
import quizBgDefault from "../../assets/img/QUIZ-Connect-Grafik_QC-Screen-BG-standard.png";

export default function PlayerNameSelector(props){

    const [playerName, setPlayerName] = useState("");

    return(
        <QuizContainer
            background={quizBgDefault}
            eventLogo={props.eventLogo} >
            <div>
                <p className={"quiz-header center"}>Gib dir einen Namen</p>
            </div>
            <div style={{textAlign:"center"}}>
                <input
                    style={{
                        padding:"1vh 1vw",
                        border: "none",
                        borderRadius: "50px",
                        fontSize: "1.8em"
                    }}
                    maxLength="25"
                    className={"center"}
                    margin={"normal"}
                    type={"text"}
                    onKeyPress={event => {
                        if (event.key === 'Enter') {
                            if(playerName !== ""){
                                props.savePlayerName(playerName)
                            }
                        }
                    }}
                    value={playerName}
                    onChange={ (e) => setPlayerName(e.target.value) }/>
            </div>
            <div style={{textAlign:"center",marginTop:"2vh"}}>
                <button
                    style={{width:"auto"}}
                    className={"quiz-button"}
                    type={"button"}
                    onClick={ (e) => {
                        if(playerName !== ""){
                            props.savePlayerName(playerName)
                        }
                    } }>
                    Diesen Namen wählen
                </button>
            </div>
        </QuizContainer>
    )
}
