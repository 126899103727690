import React, {useState} from "react";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardFooter from "../../components/Card/CardFooter";
import Button from "../../components/CustomButtons/Button";
import TextField from "@material-ui/core/TextField";
import {DropzoneArea} from "material-ui-dropzone";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import CardHeader from "../../components/Card/CardHeader";
import Checkbox from "@material-ui/core/Checkbox";
import fetcher from "../../services/Fetcher";
import Redirect from "react-router-dom/Redirect";

export default function Sound(props){
    const [sound, setSound] = useState({
        name:"",
        bgSound:false,
    });
    const [soundFiles,setSoundFiles] = useState(null)

    const handleSound = (files)=>{
        setSoundFiles(files)
    }

    const [redirectURL, redirect] = useState("");

    const uploadFiles = (respSound, soundFile) => {
        let formData = new FormData();
        formData.append(
            "files",
            soundFile,
        );

        fetcher("sound/" + respSound.id + "/file", {
            method: "POST",
            body: formData,
        });
    }
    const save = ()=>{
        if(soundFiles == null || soundFiles.length <= 0){
            alert("Bitte Sound Datei auswählen!")
            return
        }
        soundFiles.forEach((soundFile , index) => {
            const newSound = {
                name : soundFile["name"],
                bgSound: soundFiles.length <= 1 ? sound.bgSound : false
            }
            fetcher("sound", {
                method: "POST",
                body: JSON.stringify(newSound),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((resp) => resp.json())
                .then( (resp)=> uploadFiles (resp, soundFile) )
                .then( () => {
                    redirect("/admin/sounds")
                } )
        })
    }
    if(redirectURL !== ""){
        return(
            <Redirect to={redirectURL} />
        )
    }

    return(
        <Card>
            <CardHeader color={"primary"}>
                <h1>Neuer Sound</h1>
            </CardHeader>
            <CardBody>
                <GridContainer>
                    <GridItem xs={12}>
                        <label>Hintergund Sound</label>
                        <Checkbox
                            checked={sound.bgSound}
                            onClick={(e) => setSound({...sound,bgSound:e.target.checked})}
                        />
                    </GridItem>
                    <GridItem xs={6}>
                        <DropzoneArea
                            filesLimit={50}
                            onChange={handleSound}
                            dropzoneText="Sound hier rein!"
                            maxFileSize={999999999999999999}
                        />
                    </GridItem>
                </GridContainer>
            </CardBody>
            <CardFooter>
                <Button color="primary" onClick={save}>Speichern</Button>
            </CardFooter>
        </Card>
    )

}
