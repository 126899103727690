import React, {useState, useEffect} from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import {TextField} from "@material-ui/core/";
import Button from "components/CustomButtons/Button.js";
import fetcher from "services/Fetcher.js"
import CardFooter from "../../components/Card/CardFooter";
import {Link} from "react-router-dom";
import Redirect from "react-router-dom/Redirect";

export default function UserEdit(props) {

    const [user, setUser] = useState({
        email : "",
        pw : ""
    } )
    const [redirectURL, redirect] = useState("");

    useEffect( () => {
        let userId = 
            (props.match.params.userId !== "new" && props.match.params.userId !== undefined)
            ? props.match.params.userId : "";

        if(userId){
            const url = "users/" + userId
            fetcher(url)
                .then(resp => resp.json())
                .then(data => setUser(data) ) 
        }

    },[])
    
    const save = () => {
        
        const data = JSON.stringify(user)
        
        let userId =
            (props.match.params.userId !== "new" && props.match.params.userId !== undefined)
                ? props.match.params.userId : "";

        fetcher("users/" + userId, {
            method: "POST",
            body: data,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then( () => {
                redirect("/admin/users")
            } )
    }

    const inputChanged = (event) => {
        setUser({
            ...user,
            [event.target.name]: event.target.value
        });
    }
    
    if(redirectURL !== ""){
        return(
            <Redirect to={redirectURL} />
        )
    }
    
    return(
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="primary">
                        <h4>Benutzer</h4>
                    </CardHeader>
                    <CardBody>
                        <TextField
                            fullWidth
                            name={"email"}
                            value={user.email  || "" }
                            onChange={ (e) => inputChanged(e) }
                            variant="outlined"
                            margin={"normal"}
                            label="E-Mail" />

                        <TextField
                            fullWidth
                            type={"password"}
                            autoComplete={"off"}
                            name={"pw"}
                            value={user.pw }
                            onChange={ (e) => inputChanged(e) }
                            variant="outlined"
                            margin={"normal"}
                            label="Password" />
                    </CardBody>
                    <CardFooter>
                        <Button color={"primary"} onClick={save}>Speichern</Button>
                        <Link to={"/admin/users/"} >
                            <Button color={"warning"}>Abbrechen</Button>
                        </Link>
                    </CardFooter>
                </Card>
            </GridItem>
        </GridContainer>
    )
}