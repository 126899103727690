import React, { useState, useEffect } from 'react';
import fetcher from "../../services/Fetcher";
import Lobby from "./Lobby";
import QuizView from "./QuestionView.js";
import AnswerView from "./AnswerViews/AnswerView";
import AnswerVideoView from "./AnswerViews/AnswerVideoView";
import QuizFinished from "./QuizFinished";
import MemberTooLate from "./MemberTooLate";
import LobbyPending from "./LobbyPending";
import EventPending from "./EventPending";
import MemberJoin from "./MemberJoin";
import {CircularProgress} from "@material-ui/core";
import ScoreView from "./ScoreView";
import WaitingView from "./WaitingView";
import IntroView from "./IntroView";
import Over from "./Over";
import QuizContainer from "../../components/Quiz/QuizContainer";
import quizBg from "../../assets/img/QUIZ-Connect-Grafik_QC-Screen-BG-standard.png";
import QuizNotFound from "./QuizNotFound";
import QuizMasterJoin from "./QuizMasterJoin";
import QuizMasterFailed from "./QuizmasterFailed";
import OutroView from "./OutroView";
import appHeight from "../../services/MultiDisplay";
import isVideoPlaying from "../../services/Video";
import checkImage from "../../services/ImageCache";

import orangeBg from "../../assets/img/QUIZ-Connect-Grafik_QC-Screen-BG-Fehler.png";
import defaultBg from "../../assets/img/QUIZ-Connect-Grafik_QC-Screen-BG-standard.png";
import animation from "../../assets/img/Quiz-Connect-ob-das-stimmt-Animation.gif"
import waitingOther from "../../assets/img/Quiz-Connect-Pausenanimation-500.gif";

export default function Quiz(props) {
    /* -------------- rotation ---------------------------*/

    const [eventLoaded, setEventLoaded] = useState(false);
    const [connectionTimeout, setConnectionTimeout] = useState(0)
    const imgUrls = [
        orangeBg,defaultBg,animation,waitingOther
    ]

    useEffect(()=>{
        Promise.all(
            imgUrls.map( (path) => checkImage(path) )
        )
    },[])

    useEffect(()=>{

        appHeight(event)
        window.removeEventListener('resize', ()=> appHeight(event) )
        window.addEventListener('resize', ()=> appHeight(event) )

        const interval = setInterval(()=> appHeight(event), 500)
        return ()=>{
            clearInterval(interval)
        }
    },[eventLoaded])

    /* -----------------rotation END------------------- */

    const [ connection, setConnection ] = useState(null);

    const [ state, setState ] = useState({
        result: {},
        videoTime : 0,
        view : "",
        isMaster : false,
        hasMaster : false,
        props : [],
        timerEndTime : ""
    });

    let lobbyTimerTimeout= null;

    const [ event, setEvent ] = useState({eventLogoUrl :""} );
    const [ eventStore, setEventStore ] = useState(null );
    const [ userList, setUserList ] = useState([] );
    const [ userCount, setUserCount ] = useState(0 );
    const [alert, setAlert] = useState("")
    const [randomMessages, setRandomMessages] = useState("")

    let eventId = props.match.params.eventId;
    useEffect(() => {

        if(!eventId) return;

        fetcher("event/" + eventId + "/quizEvent")
            .then((resp) => resp.json())
            .then((result) => {
                setEvent(result)
                setEventLoaded(true)
            })
    }, []);

    useEffect(() => {
        if(!eventId) return;

        fetcher("quiz/" + eventId + "/status")
            .then((resp) => resp.json())
            .then((result) => {
                if( localStorage.getItem("eventStore") != null){
                    let storedEvent = localStorage.getItem("eventStore");
                    storedEvent= JSON.parse(storedEvent);

                    if(result.props.find(x => x.key == "quizRunId").value == ""
                        || !storedEvent.quizRunId
                        || storedEvent.quizRunId !== result.props.find(x => x.key == "quizRunId").value ){
                        localStorage.removeItem("eventStore")
                    }
                }

                if(result.view === "EventStarted"){
                    if( localStorage.getItem("eventStore") === null){
                        setState({
                            view: "MemberTooLate",
                            props: result.props
                        })
                    }else{
                        if(props.location.search.toLowerCase().indexOf("quizmaster") > 0){
                            setState({
                                view: "JoinMasterLobby",
                                props: result.props,
                            })
                        }else{
                            setState({
                                view: "JoinLobby",
                                props: result.props
                            })
                        }
                    }
                }else{
                    if(props.location.search.toLowerCase().indexOf("quizmaster") > 0){
                        setState({
                            view: "JoinMasterLobby",
                            props: result.props,
                        })
                    }else{
                        console.log(result)
                        setState(result)
                    }

                }
            })
    }, []);

    const registerMaster = (e, password) => {
        let storedEvent = null;
        const eventStoreStr = localStorage.getItem("eventStore");
        try {
            if(eventStoreStr !== null){
                storedEvent = JSON.parse(eventStoreStr);
            }
        }catch (e){}

        if(storedEvent === null){
            const data = {
                eventId : eventId,

                password : password
            }
            fetcher("quiz/quizmaster", {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .catch((error)=>{
                    console.log((error.statusCode))
                })
                .then((resp) => resp.json())
                .then((result) => {
                    if (result.success) {
                        localStorage.setItem("eventStore", JSON.stringify(result.eventMember))
                        setEventStore(result.eventMember)
                    } else {
                        setState({
                            view: result.view,
                            props: []
                        })
                    }
                })
        }

        if( storedEvent != null ) {
            fetcher("quiz/reconnect/" + storedEvent.eventId + "/" + storedEvent.userId)
                .then((resp) => resp.json())
                .then((json) => {
                    if (json === true) {
                        setEventStore(storedEvent)
                    } else {
                        localStorage.removeItem("eventStore")
                        register(e);
                    }
                })
        }
    };

    const register = (e) => {
        console.log("register")
        let storedEvent = null;
        const eventStoreStr = localStorage.getItem("eventStore");
        try {
            if(eventStoreStr !== null){
                storedEvent = JSON.parse(eventStoreStr);
            }
        }catch (e){}

        if(storedEvent === null){
            fetcher("quiz/" + eventId)
                .catch((error)=>{
                    console.log((error.statusCode))
                })
                .then((resp) => resp.json())
                .then((result) => {
                    if (result.success) {
                        localStorage.setItem("eventStore", JSON.stringify(result.eventMember))
                        setEventStore(result.eventMember)
                    } else {
                        setState({
                            view: result.view,
                            props: []
                        })
                    }
                })
        }

        if( storedEvent !== null ) {
            fetcher("quiz/reconnect/" + storedEvent.eventId + "/" + storedEvent.userId)
                .then((resp) => resp.json())
                .then((json) => {
                    if (json === true) {
                        setEventStore(storedEvent)
                    } else {
                       localStorage.removeItem("eventStore")
                        register(e);
                    }
                })
        }
    };

    useEffect(() => {
        if(eventStore !== null){
            createSocket();
        }
    }, [eventStore]);

    let timer = 0;
    const ping = (ws) => {
        if(timer > 0 ){
            clearTimeout(timer)
        }
        timer = setTimeout(()=> {
            ws.close()
        },12000)

        setTimeout(()=>{
            ws.send(JSON.stringify({
                action:"ping"
            }))
        },10000)
    }

    const createSocket = (connectionClosedInterval) => {

        if(connectionClosedInterval > 0) clearTimeout(connectionClosedInterval)

        const HUB_URL ="wss://koqqn395ze.execute-api.eu-central-1.amazonaws.com/production/";
        let ws = new WebSocket(HUB_URL);
        ws.onopen = () => {
            ws.send(JSON.stringify({
                action:"ping"
            }))
            setConnection(ws);
        }

        ws.onclose = () => {
            console.log("websocket closed!")
            var connectionClosedInterval = setInterval(()=>{
                if(state.view == "quizFinished") {
                    clearInterval(connectionClosedInterval)
                    connectionClosedInterval = null
                }
                if(state.view !== "quizFinished") {
                    console.log(("reconncet to websocket"))
                    createSocket(connectionClosedInterval)
                }
            }, 2000)
        }

        ws.onerror = (error) => {
            console.error(error)
            ws.close()
        }

    }

    const controlSounds = ()=>{
        let audioPlayerComment = document.getElementById("audioPlayerComment");
        let audioPlayerBg = document.getElementById("audioPlayerBg");

        if(isVideoPlaying(audioPlayerComment)){
            audioPlayerComment.currentTime = 0;
            audioPlayerComment.pause();
        }
        if(isVideoPlaying(audioPlayerBg)){
            audioPlayerBg.currentTime = 0;
            audioPlayerBg.pause();
        }
        document.getElementById("playground_root").classList.remove("playground_kino_mode")
    }
    useEffect(() => {
        if (connection) {
            const registerMessage = {
                action: "register",
                userId: eventStore.userId,
                eventId:  eventStore.eventId,
            }
            console.log(JSON.stringify(registerMessage))
            connection.send(JSON.stringify(registerMessage));

            let randomStrings = []
            connection.onmessage = (message) => {
                const data = JSON.parse(message.data)

                if(data.action && data.action === "pong" ){
                    ping(connection);
                }

                switch (data.method){
                    case "randomWaitingMessage":
                        randomStrings = [...randomStrings, data.message]
                        setRandomMessages(randomStrings)
                        setTimeout(()=>{
                            setRandomMessages([])
                            randomStrings = []
                        },10000)
                        break;
                    case "changeState":
                        let model =  JSON.parse( data.message);
                        console.log("parsed changeStateModel", model)
                        if(lobbyTimerTimeout != null){
                            clearTimeout(lobbyTimerTimeout)
                        }

                        if(model.state !== "lobby"){
                            controlSounds();
                        }
                        setState({
                            hasMaster: model.hasMaster,
                            isMaster: model.isMaster,
                            view: model.state,
                            props: model.properties,
                            result: model,
                            timerEndTime : null
                        })
                        break;
                    case "showMessage":
                        if(data.message == "WaitingTooLong"){
                            let alertMessage =  "Die Anderen warten, wir mussten ein wenig vorspulen!"
                            setAlert(alertMessage)
                            setTimeout(()=>setAlert(""), 3000)
                        }
                        break;
                    case "changeMemberState":
                        let changeStateModel =  JSON.parse( data.message);
                        console.log("parsed changeStateModel", changeStateModel)
                        if(lobbyTimerTimeout != null){
                            clearTimeout(lobbyTimerTimeout)
                        }
                        if(changeStateModel.memberView !== "lobby"){
                            controlSounds();
                        }
                        if(changeStateModel.forceStatus){
                            //show message only one time
                            if(!localStorage.getItem("message_" + changeStateModel.messageId)){
                                localStorage.setItem("message_" + changeStateModel.messageId, "1")
                                let alertMessage =  "Die Anderen warten, wir mussten ein wenig vorspulen!"
                                setAlert(alertMessage)
                                setTimeout(()=>setAlert(""), 3000)
                            }

                        }
                        setState({
                            result: changeStateModel,
                            serverTimeMillis: changeStateModel.serverTimeMillis,
                            hasMaster: changeStateModel.hasMaster,
                            isMaster: changeStateModel.isMaster,
                            videoTime: changeStateModel.currentVideoTime? changeStateModel.currentVideoTime : 0,
                            view: changeStateModel.memberView,
                            props: changeStateModel.properties,
                            timerEndTime : changeStateModel.timerEndTime
                        })
                        break;
                }
            }

        }

    }, [connection]);

    const sendMessage = (message)=>{
        console.log("sendMessage", message)
        const clientMessage = {
            userId: eventStore.userId,
            message: message,
            eventId: eventStore.eventId
        };
        fetcher("quiz/message/", {
            method: "POST",
            body: JSON.stringify(clientMessage),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then(ret => ret.json())
            .then(changeStateModel => {
                if(changeStateModel.memberView){
                    setState({
                        result :changeStateModel,
                        serverTimeMillis: changeStateModel.serverTimeMillis,
                        hasMaster: changeStateModel.hasMaster,
                        isMaster: changeStateModel.isMaster,
                        videoTime: changeStateModel.currentVideoTime? changeStateModel.currentVideoTime : 0,
                        view: changeStateModel.memberView,
                        props: changeStateModel.properties,
                        timerEndTime : changeStateModel.timerEndTime
                    })
                }
            })
    }

    const onAvatarChanged = (avatar) =>{
        const newStore= {
            ...eventStore,
            avatar:avatar
        }
        localStorage.setItem("eventStore",JSON.stringify(newStore))
        fetcher("quiz/event/" + eventStore.eventId + "/user/" + eventStore.userId + "/avatar", {
            method: "POST",
            body: JSON.stringify(avatar.id ),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
        setEventStore(newStore)
    }

    const nameChanged = (name)=>{
        const newStore= {
            ...eventStore,
            name:name
        }
        localStorage.setItem("eventStore",JSON.stringify(newStore))
        fetcher("quiz/event/" + eventStore.eventId + "/user/" + eventStore.userId + "/name", {
            method: "POST",
            body: JSON.stringify(name),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
        setEventStore(newStore)
    }

    const questionVideoEnded = () => {
        sendMessage("questionVideoEnded");
    }
    const timerEnded = ()=>{
        sendMessage("timerEnded");
    }
    const answerClicked = (answerId, time) =>{
        const answerModel = {
            time : time +"",
            userId: eventStore.userId,
            answerId: answerId,
            eventId: eventStore.eventId
        };
        fetcher("quiz/answer/", {
            method: "POST",
            body: JSON.stringify(answerModel),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
        return;
    }
    const answerVideoEnded = () => {
        sendMessage("AnswerVideoEnded");
    }
    const scoreWatched = () => {

        sendMessage("ScoreWatched");
    }
    const intoVideoEnded = ()=> {
        sendMessage("IntroWatched");
    }
    const outroVideoEnded = ()=> {
        //sendMessage("OutroWatched");
        setState({
            ...state,
            view : "quizFinished"
        })
    }
    const sendCurrentVideoTime = (time) =>{
        if(time > 0){
            fetcher("quiz/event/" + eventStore.eventId + "/user/" + eventStore.userId + "/currentVideoTime", {
                method: "POST",
                body: JSON.stringify(time + ""),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }).then(response => {
                if(!response.ok) {
                    response.text().then(text => { console.log(text + " " + time )  } )
                }

            }).catch(e => {
                console.log(e.message)
            })
        }
    }
    let stateView = (<QuizContainer background={quizBg}>
            <div style={{margin:"auto auto", height:"100%",width:"100%",textAlign: "center", maxHeight:"300px", maxWidth:"300px"}}>
                <CircularProgress style={{margin:"auto auto",height:"50%",width:"50%"}} color="secondary" />
            </div>

        </QuizContainer> );

    const lobbyTimerEnd = () => {
        lobbyTimerTimeout = setTimeout(()=>{
            window.location.reload();
        },3000)
    }
    console.log("state.view", state.view)

    if(!eventId){
        return (
            <QuizNotFound />
        )
    }

    switch (state.view){
        case "quizMasterFailed":
            stateView = (<QuizMasterFailed
                result={state.result}
                properties={state.props} eventLogo={event.eventLogoUrl} />)
            break;
        case "Over":
            stateView = (<Over result={state.result}
                               properties={state.props} eventLogo={event.eventLogoUrl} />)
            break;
        case "quizFinished":
            connection.close()
            stateView =(<QuizFinished
                result={state.result}
                eventLogo={event.eventLogoUrl}
                hasMaster={state.hasMaster}
                isMaster={state.isMaster}
                properties={state.props} userId={eventStore.userId} eventId={eventStore.eventId} />); break;
        case "ViewScore":
            stateView = (<ScoreView
                result={state.result}
                hasMaster={state.hasMaster}
                isMaster={state.isMaster}
                properties={state.props} scoreWatched={scoreWatched} />); break;
        case "AnswerVideoView":
            stateView =  (
                <AnswerVideoView
                    result={state.result}
                    hasMaster={state.hasMaster}
                    isMaster={state.isMaster}
                    videoTime={state.videoTime}
                    sendCurrentVideoQuestionTime={sendCurrentVideoTime}
                    properties={state.props} videoEnded={answerVideoEnded}/>);
            break;
        case "ViewAnswers":
            stateView =  (
                <AnswerView
                    result={state.result}
                    serverTimeMillis={state.serverTimeMillis}
                    hasMaster={state.hasMaster}
                    isMaster={state.isMaster}
                    properties={state.props}
                    timerEndTime={state.timerEndTime}
                    timerEnded = {timerEnded}
                    onAnswerClicked={answerClicked} />
            )
            break;
        case "QuestionView":
            stateView =  (<QuizView
                hasMaster={state.hasMaster}
                isMaster={state.isMaster}
                properties={state.props}
                videoTime={state.videoTime}
                videoEnded={questionVideoEnded}
                sendCurrentVideoQuestionTime={sendCurrentVideoTime} />)
            break;
        case "lobby":
            stateView =  (
                <Lobby
                    eventId={eventId}
                    result={state.result}
                    serverTimeMillis={state.serverTimeMillis}
                    hasMaster={state.hasMaster}
                    isMaster={state.isMaster}
                    lobbyTimerEnd = {lobbyTimerEnd}
                    userList={userList}
                    userCount={userCount}
                    properties={state.props}
                    eventLogo={event.eventLogoUrl}
                    eventStore={eventStore}
                    onAvatarChanged={onAvatarChanged}
                    onNameChanged={nameChanged} />)
            break;
        case "quizFinished":
            stateView =(
                <QuizFinished
                    result={state.result}
                    eventLogo={event.eventLogoUrl}
                    hasMaster={state.hasMaster}
                    isMaster={state.isMaster}
                    properties={state.props} />
                );
            break;
        case "MemberTooLate":
            stateView = (<MemberTooLate
                result={state.result}
                hasMaster={state.hasMaster}
                isMaster={state.isMaster}
                eventLogo={event.eventLogoUrl} />)
            break;
        case "EventPending":
            stateView = (<EventPending
                result={state.result}
                serverTimeMillis={state.serverTimeMillis}
                hasMaster={state.hasMaster}
                isMaster={state.isMaster}
                properties={state.props} eventLogo={event.eventLogoUrl} />)
            break;
        case "LobbyPending":
            stateView = (<LobbyPending
                result={state.result}
                hasMaster={state.hasMaster}
                isMaster={state.isMaster}
                properties={state.props} eventLogo={event.eventLogoUrl} />)
            break;
        case "JoinLobby":
            stateView = (<MemberJoin
                eventId={eventId}
                hasMaster={state.hasMaster}
                isMaster={state.isMaster}
                register={ register } properties={state.props} eventLogo={event.eventLogoUrl} />)
            break;
        case "JoinMasterLobby":
            stateView = (<QuizMasterJoin
                hasMaster={state.hasMaster}
                isMaster={state.isMaster}
                register={ registerMaster } properties={state.props} eventLogo={event.eventLogoUrl} />)
            break;
        case "Intro":
            stateView =(<IntroView
                result={state.result}
                videoTime={state.videoTime}
                hasMaster={state.hasMaster}
                isMaster={state.isMaster}
                properties={state.props}
                sendCurrentVideoQuestionTime={sendCurrentVideoTime}
                videoEnded={intoVideoEnded} />)
            break;
        case "Outro":
            stateView =(<OutroView
                result={state.result}
                videoTime={state.videoTime}
                hasMaster={state.hasMaster}
                isMaster={state.isMaster}
                properties={state.props}
                sendCurrentVideoQuestionTime={sendCurrentVideoTime}
                videoEnded={outroVideoEnded} />)
            break;
        case "Waiting":
            stateView = (<WaitingView />)
            break;
    }
    return (
        <React.Fragment>
            {stateView}

            {randomMessages.length > 0 && state.view === "ViewAnswers" &&
            randomMessages.map((rMessage, index )=>
                <div key={"random-" + index} className={"quiz-alert-random fadeInAndOut random-pos-" + index}>
                    <div className={"inner"}>
                        {rMessage}
                    </div>
                </div>
            )}

            {alert !== "" &&
                <div className={"quiz-alert fadeInAndOut"}>
                    <div className={"inner"}>
                      {alert}
                    </div>
                </div>
            }
        </React.Fragment>
    );
}
