import React from "react";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
export default function LobbyPending(props) {

    return(
        <GridContainer>
            <GridItem xs={12} >
                <h3>Das Event hat bereits begonnen!</h3>
            </GridItem>
        </GridContainer>
    )
}