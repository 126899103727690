import React, {useEffect, useState} from "react";
import QuizContainer from "../../components/Quiz/QuizContainer";
import defaultBg from "../../assets/img/QUIZ-Connect-Grafik_QC-Screen-BG-standard.png";

export default function QuizMasterFailed(props) {

    return(
        <QuizContainer
            background={defaultBg}
            eventLogo={props.eventLogo} >

            <div style={{textAlign: "center"}}>
                <h1 className={"quiz-bold quiz-header"}>
                    Spielleiter Ansicht konnte nicht geladen werden! Prüfen Sie das Passwort!
                </h1>
            </div>

        </QuizContainer>
    )
}
