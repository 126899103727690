import React from 'react'
import "video-react/dist/video-react.css";
import quizBg from "../../../assets/img/QUIZ-Connect-Grafik_QC-Screen-BG-standard.png";

import machAuge from "../../../assets/img/Quiz-Connect-Schau-vorn-Animation.gif";

import GridItem from "../../../components/Grid/GridItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import QuizContainer from "../../../components/Quiz/QuizContainer";

export default function ActionAtMasterView(props) {
    return (
        <QuizContainer
            background={quizBg}
        >
            <GridItem xs={12} className={"center"} style={{textAlign:"center",margin:"auto auto",display: "flex",alignItems: "center"}}>
                <img src={machAuge} style={{width:"80%", margin:"0 auto", display:"block"}}/>
            </GridItem>

        </QuizContainer>
    );
}
