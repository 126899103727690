import React, {useEffect, useState} from 'react'
import "video-react/dist/video-react.css";
import fetcher from "../../services/Fetcher";
import QuizContainer from "../../components/Quiz/QuizContainer";
import bg from "../../assets/img/QUIZ-Connect-Grafik_QC-Screen-BG-Fehler.png";
import bgBLue from "../../assets/img/QUIZ-Connect-Grafik_QC-Screen-BG-standard.png";
import GridItem from "../../components/Grid/GridItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import QuizMasterScore from "./Scores/QuizMasterScore";
import QuizMemberScore from "./Scores/QuizMemberScore";
import StarRatings from "react-star-ratings";
import QuizMemberLandscapeScore from "./Scores/QuizMemberLandscapeScore";

export default function QuizFinished(props) {
    const [video, setVideo] = useState(true);
    const [score, setScore] = useState(false);
    const [mailView, setMailView] = useState(false);
    const [disclaimer, setDisclaimer] = useState(false);
    const [winnerMail, setWinnerMail] = useState("");
    const [result, setResult] = useState(null);
    const [winner, setWinner] = useState(null);

    const [countdownTimer, setCountdownTimer] = useState(10);
    const [startTimer, setStartTimer] = useState(false);
    const [scoreViewTime, setScoreViewTime] = useState(0);
    const [didRate, setDidRatet] = useState(false);
    const [rating, setRating] = useState(0);

    useEffect(() => {
        if (true) {
            let time = scoreViewTime;
            const i = setInterval(function () {
                time = time - 1;
                if (time < 0) {
                    clearInterval(i)
                } else {
                    setCountdownTimer(time)
                }
            }, 1000)

            if (props.hasMaster === false || (props.hasMaster && props.isMaster)) {
                const bgSound = "/sounds/lobby_music.mp3"
                const lobbyAudio = document.getElementById("audioPlayerBg");

                lobbyAudio.src = bgSound;
                lobbyAudio.volume = 0.5;
                lobbyAudio.loop = true;
                lobbyAudio.currentTime = 0;
                lobbyAudio.onended = function () {
                    lobbyAudio.currentTime = 0;
                };
                lobbyAudio.load();
                lobbyAudio.pause();
                lobbyAudio.play();
            }

        }
    }, [startTimer])

    useEffect(() => {
        let scoreTime = 10;
        fetcher("quiz/" + props.eventId + "/result/" + props.userId)
            .then(resp => resp.json())
            .then((resp) => {
                console.log(resp)
                setWinner(false)
                setResult(resp.data)

                if (resp.scoreTime) {
                    scoreTime = resp.scoreTime
                    setScoreViewTime(scoreTime)
                }
                videoEnded(scoreTime);

            })
    }, [])

    const videoEnded = (scroeTime) => {
        document.getElementById("playground_root").classList.remove("playground_kino_mode")
        document.getElementById("videoPlayer").classList.add("invisible");
        setVideo(false)
        setStartTimer(true)
        setScore(true)

        if (props.hasMaster === false || (props.hasMaster && props.isMaster == false)) {
            setTimeout(switchMode, scroeTime * 1000)
        }
    }
    const switchMode = () => {
        setScore(false)
        if (result == null) {
            fetcher("quiz/" + props.eventId + "/result/" + props.userId)
                .then(resp => resp.json())
                .then((result) => {
                    console.log(result.data)
                    if (result.data.placement <= 3 && props.hasMaster == false) {
                        setMailView(true)
                    } else {
                        if (props.hasMaster === false || (props.hasMaster && props.isMaster == false)) {
                            setDisclaimer(true)
                        }
                    }
                })
        } else {
            if (result.data.placement <= 3 ) {
                setMailView(true)
            } else {
                setDisclaimer(true)
            }
        }
    }

    const saveMail = () => {
        if (winnerMail == "") return;

        const data = {
            mail: winnerMail
        }
        fetcher("quiz/" + props.eventId + "/winnermail/" + props.userId, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then(resp => resp.json())
            .then(() => {
                setWinner(false)
                setDisclaimer(true)
                setMailView(false)
            })
    }
    // localStorage.removeItem("eventStore");
    if (video) {
        return (
            <React.Fragment>
                <div id="finisher" className="center finished">
                </div>
            </React.Fragment>
        );
    }
    if (result == null) {
        return <div></div>
    }

    if (score) {
        if (props.isMaster) {
            return (<QuizMasterScore result={result} eventLogo={props.eventLogo}/>)
        } else if(props.isMaster === false && props.hasMaster) {
            return (<QuizMemberScore result={result} countdownTimer={countdownTimer} eventLogo={props.eventLogo}/>)
        }else{
            return (
                <QuizMemberLandscapeScore
                    result={result}
                    countdownTimer={countdownTimer}
                    eventLogo={props.eventLogo}/>
                    )
        }
    }

    function rate() {
        fetcher("quiz/" + props.eventId + "/rate/" + props.userId, {
            method: "POST",
            body: JSON.stringify({rating: rating}),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
        setDidRatet(true)
    }

    if (mailView) {
        return (
            <QuizContainer background={bg}>
                <div className={"winner-mail-wrapper upper"}>
                    <div className={"mail-greeting-wrapper"}>
                        <p className={"winner-mail-header"}>Glückwunsch</p>
                        <p className={"winner-mail-subheader"}>Möchtest du deinen Preis?</p>
                    </div>
                    <div className={"mail-input-wrapper upper"}>
                        <p className={"winner-mail-text"}>Hinterlasse uns deine EMail-Adresse</p>
                        <input className={"winner-mail-input"} type={"text"} value={winnerMail}
                               onChange={e => setWinnerMail(e.target.value)}
                               onKeyPress={event => {
                                   if (event.key === 'Enter') {
                                       saveMail()
                                   }
                               }}
                        />
                        <button className={"winner-mail-button"} type={"text"} onClick={saveMail}>
                            Absenden
                        </button>
                    </div>
                </div>
            </QuizContainer>
        )
    }

    if (disclaimer) {
        return (
            <QuizContainer
                eventLogo={props.eventLogo}
                background={bgBLue}
            >
                <div className={"disclaimer-header-wrapper"}>
                    <div className={"disclaimer-ty-header upper"}><span>Danke</span></div>
                    <div className={"disclaimer-ty-subheader upper"}><span>Für's Mitmachen!</span></div>
                </div>

                {didRate == false &&
                <div className={"disclaimer-seccond-wrapper"}>
                    <span>Wieviele Sterne gibst du uns?</span><br/>
                    <StarRatings
                        rating={rating}
                        stopColorStyle={"#ff9800"}
                        starRatedColor="#ff9800"
                        starHoverColor={"#ff9800"}
                        changeRating={(newRating, name) => setRating(newRating)}
                        starDimension="50px"
                        numberOfStars={5}
                        name='rating'
                    />
                    <div style={{textAlign: "center", marginTop: "2vh"}}>
                        <button
                            style={{width: "auto"}}
                            className={"quiz-button"}
                            type={"button"}
                            onClick={rate}>
                            Quiz bewerten
                        </button>
                    </div>
                </div>
                }

                {didRate == true &&
                <div className={"disclaimer-seccond-wrapper"}>
                    <span style={{fontWeight: "bold"}}>Vielen Dank für deine Bewertung!</span>
                </div>
                }
                {didRate == true &&
                <div className={"disclaimer-seccond-wrapper"}>
                    <span>Möchtest Du Dein eigenes Quiz?</span> <br/>
                    <span>Besuch uns auf www.quiz-connection.de!</span> <br/>
                </div>
                }
            </QuizContainer>
        )
    }

    return (
        <GridItem xs={12} style={{textAlign: "center"}}>
            <CircularProgress/>
        </GridItem>
    )
}
