import React, {useState} from "react";
import QuizContainer from "../../components/Quiz/QuizContainer";
import quizBgDefault from "../../assets/img/QUIZ-Connect-Grafik_QC-Screen-BG-standard.png";
import AvatarSwiper from "./AvatarSwiper";

export default function AvatarSelector(props){
    const [avatar, setAvatar] = useState(0);

    return(
        <QuizContainer
            background={quizBgDefault}
            eventLogo={props.eventLogo} >
            <div>
                <p style={{fontSize:"1.6em"}} className={"quiz-header center"}>Wie siehst du aus?</p>
            </div>
            <div style={{textAlign:"center"}}>
                {props.avatars.length > 0 &&
                    <AvatarSwiper
                        avatars = {props.avatars}
                        avatar = {avatar}
                        setAvatar={setAvatar}
                    />
                }
            </div>
            <div style={{textAlign:"center",marginTop:"2vh"}}>
                <button
                    style={{width:"auto"}}
                    className={"quiz-button"}
                    type={"button"}
                    onClick={ (e) => props.saveAvatar(avatar) }>
                    So sehe ich aus
                </button>
            </div>
        </QuizContainer>
    )
}
