import React, {useState} from "react";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardFooter from "../../components/Card/CardFooter";
import Button from "../../components/CustomButtons/Button";
import {DropzoneArea} from "material-ui-dropzone";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import CardHeader from "../../components/Card/CardHeader";
import fetcher from "../../services/Fetcher";
import Redirect from "react-router-dom/Redirect";

export default function AvatarEditor(props){
    const [avatarFiles,setAvatarFiles] = useState(null)

    const handleAvatar = (files)=>{
        setAvatarFiles(files)
    }

    const [redirectURL, redirect] = useState("");

    const uploadFiles = (resp,avatarFile ) => {
        let formData = new FormData();
        formData.append(
            "files",
            avatarFile,
        );
        return fetcher("avatar/" + resp.id + "/file", {
            method: "POST",
            body: formData,
        });
    }
    const save = ()=>{
        if(avatarFiles == null || avatarFiles.length <= 0){
            alert("Bitte Datei auswählen!")
            return
        }

        avatarFiles.forEach( (file ,index) =>{
            fetcher("avatar", {
                method: "POST",
                body: JSON.stringify({
                    name: file["name"]
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((resp) => resp.json())
                .then((resp)=>{
                    uploadFiles(resp, file).then( () => {

                    })
                })
            redirect("/admin/avatars")
        })
    }
    if(redirectURL !== ""){
        return(
            <Redirect to={redirectURL} />
        )
    }

    return(
        <Card>
            <CardHeader color={"primary"}>
                <h1>Neuer Avatar</h1>
            </CardHeader>
            <CardBody>
                <GridContainer>
                    <GridItem xs={6}>
                        <DropzoneArea
                            filesLimit={50}
                            onChange={handleAvatar}
                            dropzoneText="Avatar hier rein!"
                            maxFileSize={999999999999999999}
                        />
                    </GridItem>
                </GridContainer>
            </CardBody>
            <CardFooter>
                <Button color="primary" onClick={save}>Speichern</Button>
            </CardFooter>
        </Card>
    )

}
