import isVideoPlaying from "./Video";

const appHeight = (event) => {
    let forceLandscape;
    //Wenn wir einen Quiz Master haben, dann spielen wir im Hochformat (client), denn Videos müssen nicht geschaut werden (die sind auf der Leihnwand)
    if(event && event.withQuizLeader){
        //force portrait
        forceLandscape = false
    }else{
        //force landscape
        forceLandscape =  true
    }

    let is_landscape;
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.toLowerCase().indexOf("android") > -1;
    const isIPhone= ua.toLowerCase().indexOf("iphone") > -1;

    if (isAndroid) {
        is_landscape = (window.screen.height < window.screen.width);
    }else{
        const mql = window.matchMedia("(orientation: landscape)");
        is_landscape = mql.matches
    }
    const isTouchDevice = () => 'ontouchstart' in window || 'onmsgesturechange' in window;

    if( document.getElementById("playground_root") != null && isTouchDevice()){
        const video = document.getElementById("videoPlayer");
        if(
            (is_landscape && forceLandscape == false) ||
            (!is_landscape && forceLandscape)
        ){

            if(document.getElementById("videoPlayer") && isVideoPlaying(video)){
                document.getElementById("videoPlayer").classList.add("invisible");
            }

            document.getElementById("playground_root").classList.add("hidden_dreck")
            document.getElementById("please_rotate").classList.remove("hidden_dreck")

        }else{

            if(document.getElementById("videoPlayer") && isVideoPlaying(video)){
                document.getElementById("videoPlayer").classList.remove("invisible");
            }

            document.getElementById("playground_root").classList.remove("hidden_dreck")
            document.getElementById("please_rotate").classList.add("hidden_dreck")
        }

    }
    const doc = document.documentElement
    if(isIPhone){
        const tag = document.activeElement.tagName.toLowerCase();
        if(tag == "input"){
            return
        }
    }
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    doc.style.setProperty('--app-width', `${window.innerWidth}px`)
}

export default appHeight
