import React, {useState, useEffect} from "react";


import {makeStyles} from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import TextField from "@material-ui/core/TextField";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Icon from "@material-ui/core/Icon";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import {DropzoneArea} from "material-ui-dropzone";
import {Autocomplete} from "@material-ui/lab";
import Redirect from "react-router-dom/Redirect";
import {
    Table,
    TableHead,
    TableFooter,
    TableBody,
    TableCell,
    TableRow,
    Checkbox,
} from "@material-ui/core";
import fetcher from "services/Fetcher.js"
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(styles);

export default function Questions(props) {
    let questionId = null;

    const defaultQuestionModel = {
        text: "",
        category: "",
        questionVideoFile: null,
        q: null,
        answerImageFile: null,
        answers: [],
        resultExcluded: false,
        answerVideoDuration: "",
        questionVideoDuration: ""
    };
    const [question, setQuestion] = useState(defaultQuestionModel);
    const [redirectURL, redirect] = useState("");
    const [answerFileName, setAnswerFileName] = useState("");
    const [questionFileName, setQuestionFileName] = useState("");
    const [answerImageFileName,setAnswerImageFileName] = useState("");

    const [uploading, setUploading] = useState(false);

    const [categories, setCategories] = useState([]);

    const alphabet = [ 'A','B', 'C', 'C', 'E', 'F' ]

    useEffect(() => {
        const fetchData = async() => {
            questionId = props.match.params.questionId;
            if (questionId === "new") {
                questionId = null;
            }
            if (questionId) {
                const response = await fetcher("Question/" + questionId);
                const data = await response.json();
                setQuestion(data);

                setAnswerImageFileName(data.answerImageFile)
                setQuestionFileName(data.questionVideoFile)
                setAnswerFileName(data.answerVideoFile)
            }

            const response = await fetcher("Question/Categories");
            const data = await response.json();
            setCategories(data);
        }
        fetchData();
     }, []);

    const addAnswer = function () {
        const newAnswers = [...question.answers, {text: "", correct: false, position : question.answers.length + 1 }];
        const newQuestion = {
            ...question,
            answers: newAnswers,
        };
        setQuestion(newQuestion);
    };

    const handleTextChange = (e, key) => {
        const answersUpdated = question.answers.map((data, aKey) => {
            if (key === aKey) {
                const updatedItem = {
                    ...data,
                    text: e.target.value,
                };
                return updatedItem;
            }
            return data;
        });
        setQuestion({...question, answers: answersUpdated});
    };

    const handleCheckboxChange = (e, key) => {
        const answersUpdated = question.answers.map((data, aKey) => {
            if (key === aKey) {
                return {...data, correct: e.target.checked};
            }
            return data;
        });
        setQuestion({...question, answers: answersUpdated});
    };

    const handleRemove = (key) => {
        question.answers.splice(key, 1);
        const newList = [...question.answers];
        setQuestion({...question, answers: newList});
    };

    const handleQuestionVideo = (files) => {
        setQuestion({
            ...question,
            questionVideoFile: files[0],
        });
        if(files[0])
            setQuestionFileName(files[0].name)

    };

    const handleAnswerVideo = (files) => {
        setQuestion({
            ...question,
            answerVideoFile: files[0],
        });
        if(files[0])
         setAnswerFileName(files[0].name)
    };

    const handleAnswerImage= (files) => {
        setQuestion({
            ...question,
            answerImageFile: files[0],
        });
        if(files[0])
            setAnswerImageFileName(files[0].name)
    };

    const questionTextChanged = (e) => {
        setQuestion({
            ...question,
            text: e.target.value,
        });
    }
    const questionCategoryChanged = (e, v) => {
        setQuestion({
            ...question,
            category: v,
        });
    }

    const onInputCategoryChange = (e, v) => {
        if(v){
            setQuestion({
                ...question,
                category:v.title,
            });
        }
    }

    const deleteQuestionVideo = () => {
        setQuestion({
            ...question,
            questionVideoFile: null,
        });
        setQuestionFileName("")
    };

    const deleteAnswerImage = () => {
        setQuestion({
            ...question,
            answerImageFile: null,
        });
        setAnswerImageFileName("")
    };

    const deleteAnswerVideo = () => {
        setQuestion({
            ...question,
            answerVideoFile: null,
        });
        setAnswerFileName("")
    };

    const classes = useStyles();

    const handleSubmit = (event) => {
event.preventDefault()
        const queryParams = new URLSearchParams(window.location.search);
        let directory = queryParams.get('directory');
        if(!directory) directory = "00000000-0000-0000-0000-000000000000"

        console.log("directory", directory)
        setUploading(true);

        event.preventDefault();
        let questionId = props.match.params.questionId;
        if (questionId === "new") {
            questionId = null;
        }

        let url = "Question/";
        if (questionId != null) {
            url += questionId;
        }
        const data = {
            ...question
        }
        if(data.answerVideoFile != null){
            data.answerVideoFile ="video";
        }else{
            data.answerVideoFile ="";
        }

        if(data.questionVideoFile != null){
            data.questionVideoFile ="video";
        }else {
            data.questionVideoFile ="";
        }

        if(data.answerImageFile != null){
            data.answerImageFile ="img";
        }else {
            data.answerImageFile ="";
        }

        data.directory = directory

        fetcher(url, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((resp) => resp.json())
            .then(uploadFiles)
            .then( () => {
                redirect("/admin/questions/?directory=" + directory)
            } )
    };

    const uploadFiles = (data) => {

        return new Promise(

            function(resolve, reject) {
                if ( !data.id || (question.questionVideoFile == null && question.answerVideoFile == null) ) {
                    resolve({success:false, message:"no_id"});
                }
                uploadFile(data.id, question.questionVideoFile, "questionVideo").then(()=>{
                    uploadFile(data.id, question.answerVideoFile, "answerVideo").then(()=>{
                        uploadFile(data.id, question.answerImageFile, "answerImage").then(()=>{
                            resolve({success:true, message:"done"});
                        })
                    })
                });
            });
    };

    const uploadFile = (id,file,url)=>{
        return new Promise(
            function(resolve, reject) {
                if (file == null) {
                    resolve({success:false, message:"file is null"})
                }
                let formData = new FormData();
                formData.append(
                    "files",
                    file,
                );
                fetcher("Question/" + id + "/" + url, {
                    method: "POST",
                    body: formData,
                }).then(() => resolve({success:true, message:"ok"}) )
            })
    }

    const uploadAnswerVideo = (data)=>{
        return new Promise(
            function(resolve, reject) {
                if (question.answerVideoFile == null) {
                    resolve({success:false, message:"answerVideoFile is null"})
                }
                let formData = new FormData();
                formData.append(
                    "files",
                    question.answerVideoFile,
                );
                fetcher("Question/" + data.id + "/answerVideo", {
                    method: "POST",
                    body: formData,
                }).then(() => resolve({success:true, message:"ok"}) )
            })
    }

    const uploadAnswerImage= (data) =>{
        let formData = new FormData();
        formData.append(
            "files",
            question.answerImageFile,
        );
        return fetcher("Question/" + data.id + "/answerImage", {
            method: "POST",
            body: formData,
        });
    }

    const handleError = (message, error) => {
    };

    if(redirectURL !== ""){
        return(
            <Redirect to={redirectURL} />
        )
    }

    if(uploading){
        return (
            <GridContainer>
                <GridItem xs={12} style={{textAlign:"center"}}>
                    <CircularProgress />
                    <p style={{color:"black"}}>Alles wird gespeichert ....</p>
                </GridItem>
            </GridContainer>
        )
    }

    return (
        <form onSubmit={handleSubmit}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary">
                            <h3>Neue Frage erstellen</h3>
                        </CardHeader>
                    </Card>
                    <Card>
                        <CardBody>
                            <h4>Frage</h4>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={10}>
                                    <TextField
                                        fullWidth
                                        id="outlined-basic"
                                        label="Frage"
                                        variant="outlined"
                                        margin="normal"
                                        value={question.text}
                                        onChange={(e) => questionTextChanged(e)}
                                    />
                                    <Autocomplete
                                        options={categories}
                                        freeSolo
                                        getOptionLabel={(option) => option.title}
                                        inputValue={question.category}
                                        onChange={onInputCategoryChange}
                                        onInputChange={questionCategoryChanged}
                                        renderInput={(params) => (
                                            <TextField
                                                fullWidth
                                                margin="normal"
                                                {...params}
                                                label="Kategorie"
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </GridItem>

                                <GridItem xs={12} sm={6} md={4}>
                                    <h4>Frage Video ({question.questionVideoDuration})</h4>
                                    <DropzoneArea
                                        acceptedFiles={['video/*']}
                                        maxFileSize={999999999}
                                        onChange={handleQuestionVideo}
                                        dropzoneText="Frage Video hier rein!"
                                    />
                                    {questionFileName && (
                                        <Button
                                            size="sm"
                                            color="warning"
                                            onClick={deleteQuestionVideo}
                                        >
                                            {questionFileName} entfernen!
                                        </Button>
                                    )}

                                    {question.questionVideoFileUrl  && (

                                        <Button size="sm" target={"_blank"} variant="contained" color="" href={question.questionVideoFileUrl}>
                                            Öffnen
                                        </Button>
                                    )}
                                </GridItem>

                                <GridItem xs={12} sm={6} md={4}>
                                    <h4>Antwort Video ({question.answerVideoDuration})</h4>
                                    <DropzoneArea
                                        onChange={handleAnswerVideo}
                                        dropzoneText="Antwort Video hier rein!"
                                        maxFileSize={999999999999999999}
                                    />
                                    {answerFileName && (
                                        <Button
                                            size="sm"
                                            color="warning"
                                            onClick={deleteAnswerVideo}
                                        >
                                            {answerFileName} entfernen!
                                        </Button>
                                    )}

                                    {question.answerVideoFileUrl  && (

                                        <Button size="sm" target={"_blank"} variant="contained" color="" href={question.answerVideoFileUrl}>
                                            Öffnen
                                        </Button>
                                    )}

                                </GridItem>

                                <GridItem xs={12} sm={6} md={4}>
                                    <h4>Themen Bild</h4>
                                    <DropzoneArea
                                        onChange={handleAnswerImage}
                                        dropzoneText="Themen Bild hier rein!"
                                        maxFileSize={999999999999999999}
                                    />
                                    {answerImageFileName && (
                                        <Button
                                            size="sm"
                                            color="warning"
                                            onClick={deleteAnswerImage}
                                        >
                                            {answerImageFileName} entfernen!
                                        </Button>
                                    )}

                                    {question.answerImageFileUrl  && (

                                        <Button size="sm" target={"_blank"} variant="contained" color="" href={question.answerImageFileUrl}>
                                            Öffnen
                                        </Button>
                                    )}

                                </GridItem>

                            </GridContainer>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <h4>Antworten</h4>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <Table>
                                        <TableHead>
                                            <TableRow className={classes.tableHeadRow}>
                                                <TableCell></TableCell>
                                                <TableCell>Antwort</TableCell>
                                                <TableCell>Richtige Antwort?</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {question.answers.map((data, key) => {
                                                return (
                                                    <TableRow key={key}>
                                                        <TableCell>
                                                            <Button
                                                                size="sm"
                                                                color="primary"
                                                                onClick={(e) => {
                                                                    handleRemove(key);
                                                                }}
                                                            >
                                                                -
                                                            </Button>
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                fullWidth
                                                                id="outlined-basic"
                                                                value={data.text}
                                                                label={"Antwort " + alphabet[data.position - 1]}
                                                                variant="outlined"
                                                                onChange={(e) => {
                                                                    handleTextChange(e, key);
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Checkbox
                                                                checked={data.correct}
                                                                onChange={(e) => {
                                                                    handleCheckboxChange(e, key);
                                                                }}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TableCell colSpan="3">
                                                    <Button size="sm" color="primary" onClick={addAnswer}>
                                                        +
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </GridItem>

                            </GridContainer>
                        </CardBody>
                        <Card>
                            <CardBody>
                                <label>Nicht ergebnisrelevant</label>
                                <Checkbox
                                    name={"resultExcluded"}
                                    checked={question.resultExcluded}
                                    tabIndex={-1}
                                    onClick={(e) => setQuestion({...question, resultExcluded : e.target.checked})}
                                />
                            </CardBody>
                        </Card>
                        <CardFooter>
                            <Button color="primary" type="submit">
                                Speichern
                            </Button>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        </form>
    );
}
