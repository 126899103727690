import React, {useEffect, useState} from 'react'

import "video-react/dist/video-react.css";
import ActionAtMasterView from "./QuizMaster/ActionAtMasterView";
import VideoStream from "../../services/VideoStream";

export default function QuestionView(props) {

    const [videoUrl, setVideoUrl] = useState("");
    useEffect(()=>{
        if(props.hasMaster === false || (props.hasMaster && props.isMaster)) {
            const url = props.properties.find(val => val.key === "videoUrl")
            if (url && url.value) {
                setVideoUrl(url.value)
            } else {
                props.videoEnded();
            }
        }
    },[])

    if(props.hasMaster && !props.isMaster){
        return (
            <ActionAtMasterView />
        );
    }else if(videoUrl != ""){
        return (
            <VideoStream
                url={videoUrl}
                videoEnded={props.videoEnded}
                videoTime={props.videoTime}
                sendCurrentVideoQuestionTime={ props.sendCurrentVideoQuestionTime}
            />
        );
    }else {
        return  <div style={{height:"100vh",width:"100vh",backgroundColor:"black"}}></div>
    }
}
