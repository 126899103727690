import React, {useEffect, useState} from "react";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import fetcher from "../../services/Fetcher";
import {Table} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import ResultDetails from "./ResultDetails";
import {BackspaceTwoTone} from "@material-ui/icons";

export default function Results(props){

    const [finished, setFinished] = useState([])
    const [folder, setFolder] = useState("")
    const [results, setResults] = useState([])
    const [keys, setKeys] = useState([])
    const [selected,setSelected] = useState(null)

    useEffect(()=>{
        fetcher("quiz/finished/")
            .then(r => r.json())
            .then( results => {
                setResults(results)
            })
    },[]);

    useEffect( ()=>{
        let list =  results.map(res => res.split("/")[0] )
        let newArray = list.filter((element, index, array) => array.indexOf(element) === index);
        setFinished(newArray)
    },[results]);

    useEffect( ()=>{
        if(folder != ""){
            let list =  results
                .filter(res => res.startsWith(folder))
            console.log(list)
            setKeys(list)
        }
    },[folder])


    if(selected != null){
        return  <ResultDetails selection={selected} back={()=>setSelected(null)}/>
    }
    return(
        <React.Fragment>

            {folder == "" &&
            <Card>
                <CardHeader color={"primary"}>
                    <h2>Vergangene Quiz</h2>
                </CardHeader>
                <CardBody>

                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Event
                                </TableCell>
                                <TableCell>

                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {finished.map( run =>
                                <TableRow key={run + Date.now() + "_key"}>
                                    <TableCell>
                                        {run}
                                    </TableCell>
                                    <TableCell>
                                        <Button color="primary" onClick={()=>setFolder(run)}>öffnen</Button>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </CardBody>
            </Card>
            }

            {folder != "" &&

            <Card>
                <CardHeader color={"primary"}>
                    <h2>Vergangene Quiz</h2>
                </CardHeader>
                <CardBody>
                    <Button onClick={()=>setFolder("")}>Zurück</Button>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Event
                                </TableCell>
                                <TableCell>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {keys.map( run =>
                                <TableRow key={run +  Date.now() + "_key"}>
                                    <TableCell>
                                        {run}
                                    </TableCell>
                                    <TableCell>
                                        <Button color="primary" onClick={()=>setSelected(run)}>Anzeigen</Button>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </CardBody>
            </Card>
            }

        </React.Fragment>
    )
}
