let API_URL = "https://api.wegmeth.cloud/api/v1/";
//API_URL="http://localhost:5002/api/v1/";

function updateOptions(options) {
    const token = localStorage.getItem("token")

    const update = { ...options };
    if (token) {
        update.headers = {
            ...update.headers,
            Authorization: "Bearer " + token,
        };
    }
    return update;
}

export default function fetcher(url, options) {
    return new Promise((resolve, reject) => {
        fetch(API_URL + url, updateOptions(options))
            .then((response) => {
                if(response.status === 401){
                    localStorage.removeItem('token');
                    window.location.href = "/admin";
                }
                resolve(response)
            })
            .catch(function() {
                reject(false)
                console.log("error");
            });
    })
    return promise;
}
