import Countdown from "react-countdown";
import QuizContainer from "../../../components/Quiz/QuizContainer";
import React from "react";
import renderer from "./AnswerViewCountdownRenderer";

export default function PortraitAnswerView(props){

    const alphaNum = [];
    alphaNum[0] = "A";
    alphaNum[1] = "B";
    alphaNum[2] = "C";
    alphaNum[3] = "D";
    alphaNum[4] = "E";
    alphaNum[5] = "F";

    const isMaster = props.isMaster
    const hasMaster = props.hasMaster

    return(
        <QuizContainer
            backgroundColor={"black"}
            theme={props.answerImageUrl}
        >
            <div style={{textAlign: "center"}}>
                <p className={"questionText"}>{props.questionText}</p>
            </div>
            <div style={{textAlign: "center"}}>
                <div>
                    {props.answers.map((value, index) => {
                        let aTime = "";
                        let className = "answer-button"
                        if (props.selection === value.id) {
                            if (props.answerTime > 0) {
                                aTime = " (" + props.answerTime / 1000 + " Sek.)"
                            }
                            className = "answer-button answer-button-selected"
                        } else {
                            className = "answer-button answer-button-not-selected"
                        }
                        return (
                            <div className={"quiz-wrapper"} key={"answer_" + index}>
                                <div className={"answer-button-prefix"}><span>{alphaNum[index]}</span></div>
                                <a
                                    className={className}
                                    id={"answerBtn_" + index}
                                    type={"button"}
                                    onClick={(e) => {
                                        props.answerClicked("answerBtn_" + index, value.id)
                                    }}
                                >
                                    {value.text}
                                    <span className={"right"} style={{float: "right"}}>{aTime}</span>
                                </a>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div>
                <Countdown
                    onComplete={props.timerDidEnd}
                    date={props.timerEndTime}
                    renderer={({ hours, minutes, seconds, completed })=> renderer({ hasMaster, isMaster, hours, minutes, seconds, completed} ) }/>
            </div>
        </QuizContainer>
    )
}
