import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import {Link} from "react-router-dom";
import Button from "../CustomButtons/Button";
import FolderIcon from "@material-ui/icons/Folder";
import {CheckCircleOutline, ErrorOutline} from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";

import { useHistory } from "react-router-dom";

export default function QuestionBrowser(props){
    let history = useHistory();
    return(
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Status</TableCell>
                    <TableCell>Frage</TableCell>
                    <TableCell>Kategorie</TableCell>
                    <TableCell>Datum</TableCell>
                    <TableCell>Aktion</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>

                { props.currentDirectory != null && props.currentDirectory.isRoot === false &&
                <TableRow key={"dir_" }>
                    <TableCell><KeyboardBackspaceIcon /></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                            <Button onClick={
                                ()=> {
                                    history.push(props.from + ( props.currentDirectory.parent ? "?directory=" + props.currentDirectory.parent : ""))
                                    props.directoryChange(props.currentDirectory.parent )
                                }
                            }>Zurück</Button>
                    </TableCell>
                </TableRow>
                }

                {props.directories.map((dir, index) =>
                    <TableRow key={"dir_" + index}>
                        <TableCell><FolderIcon /></TableCell>
                        <TableCell>{dir.name}</TableCell>
                        <TableCell>{dir.createdAt}</TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                                <Button onClick={ ()=> {
                                    history.push(props.from + "?directory=" + dir.id)
                                    props.directoryChange(dir.id )
                                }}>Öffnen</Button>

                            {props.deleteFolder &&
                            <Button onClick={() => props.deleteFolder(dir.id)} color={"danger"}>Löschen</Button>
                            }

                        </TableCell>
                    </TableRow>
                )}
                {props.questions.map((data, key) => {
                    return (
                        <TableRow key={key}>
                            <TableCell>
                                {data.status === "checked" &&
                                <CheckCircleOutline />
                                }
                                {data.status !== "checked" &&
                                <ErrorOutline />
                                }

                            </TableCell>
                            <TableCell>{data.text}</TableCell>
                            <TableCell>{data.category}</TableCell>
                            <TableCell>{data.date}</TableCell>

                            <TableCell>
                                {props.canEdit &&
                                <Link to={"/admin/QuestionEdit/" + data.id + "?directory=" + props.directory}>
                                    <Button color="primary">
                                        Bearbeiten
                                    </Button>
                                </Link>
                                }

                                {props.questionSelected && props.questionSelected(data.id) == false &&
                                <Button color="warning" onClick={() => props.selectQuestion(data)}>
                                    Wählen
                                </Button>
                                }

                                {props.questionDelete &&
                                <Button color="warning" onClick={() => props.questionDelete(data.id)}>
                                    Löschen
                                </Button>
                                }
                                {props.moveQuestion &&
                                <FormControl style={{minWidth:"320px",marginLeft:"20px"}} className={"formControl"}>
                                    <InputLabel id="demo-simple-select-label">Frage veschieben:</InputLabel>
                                    <Select value={""} onChange={(event)=> props.moveQuestion(data.id,event.target.value)} >
                                        {props.currentDirectory &&
                                        <MenuItem key={"dir_sel_00"} value={props.currentDirectory.parent ? props.currentDirectory.parent : "00000000-0000-0000-0000-000000000000"  }>Übergeordnet</MenuItem>
                                        }
                                        {props.directories.map((value, index) =>
                                            <MenuItem key={"dir_sel_"+index} value={value.id}>{value.name}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                                }

                            </TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    )
}
