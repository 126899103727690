import React from 'react';
import Swiper from 'react-id-swiper';
import 'assets/css/swiper.css'

export default function AvatarSwiper(props) {
    const params = {
        slidesPerView: 6,
        spaceBetween: 10,
        slidesPerGroup: 2,
        centeredSlides: true,
        grabCursor: true,
        breakpoints: {
            1024: {
                slidesPerView: 8,
                spaceBetween: 40
            }
        }
    }

    return (
        <Swiper {...params}>
            {props.avatars.map((item, index) => {
                    let cname = index === props.avatar ? "avatar-selected" : "avatar-notselected"
                    return (
                        <div style={{width: "5px"}} key={"avatar_" + index} className={cname} onClick={() => {
                            props.setAvatar(index)
                        }}>
                            <img style={{maxWidth: "100%", maxHeight: "25vh"}} src={item.path}/>
                        </div>)
                }
            )}
        </Swiper>
    )
};
