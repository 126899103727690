import React from "react";
import QuizContainer from "../../components/Quiz/QuizContainer";
import GridItem from "../../components/Grid/GridItem";
import quizBg from "../../assets/img/QUIZ-Connect-Grafik_QC-Screen-BG-standard.png";
export default function QuizNotFound(){

    const pStyle = {
        fontSize:"1.5em"
    }

    const linkStyle = {
        whiteSpace: "nowrap",
        width: "60vw",
        maxWidth : "300px"
    }
    return(
        <QuizContainer background={quizBg}>
           <div className={"center"}>
               <p style={pStyle} className="questionText center" >
                   Hallo Quizfreund! <br/>
                   Ohne Einladung zu einem Quiz,
                   läuft hier nicht viel.<br />
                   Alle Infos über unsere Quiz-Platform <br />
                   auf quiz-connection.de
               </p>
           </div>
            <div xs={12}  >
                <div className={"center"}>
                    <a  target={"_blank"} style={linkStyle} className={"quiz-button"} href={"https://quiz-connection.de"}>da will ich hin</a>
                    <a  target={"_blank"} style={linkStyle} className={"quiz-button"} href={"https://quiz-connection.de/imprint"}>Impressum</a>
                </div>
            </div>
        </QuizContainer>
    )
}
