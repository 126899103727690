import React, {useEffect, useState} from 'react'

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem";

import "video-react/dist/video-react.css";
import QuizContainer from "../../../components/Quiz/QuizContainer";
import quizBg from "../../../assets/img/QUIZ-Connect-Grafik_QC-Screen-BG-standard.png";
import ActionAtMasterView from "../QuizMaster/ActionAtMasterView";
import VideoStream from "../../../services/VideoStream";

export default function AnswerVideoView(props) {

    const [videoUrl, setVideoUrl] = useState("");
    const [init, setInit] = useState(false);
    useEffect(()=>{
        if(props.hasMaster === false || (props.hasMaster && props.isMaster)) {
            const url = props.properties.find(val => val.key === "url")
            if (url && url.value != "") {
                setVideoUrl(url.value)
            }
            setInit(true)
        }
    },[])


    if( videoUrl == "" && init){
        let text = props.properties.find(val => val.key === "text")
        let result = null;

        if(text != null && text.value != null) {
            if (text.value) {
                text = text.value;
                setTimeout(props.videoEnded, 2000)
                result = (
                    <h2 className="quiz-bold quiz-header center" style={{textAlign:"center"}}>{text}</h2>
                )
            }
        }
        return (
            <QuizContainer
                background={quizBg}
            >
                <GridItem xs={12}>
                    <h1 className={"quiz-bold center"}>Und die Antwort lautet:</h1>
                </GridItem>
                <GridItem xs={12} >
                    {result}
                </GridItem>
            </QuizContainer>
        );
    }else{

        if(props.hasMaster && !props.isMaster){
            return (
                <ActionAtMasterView />
            );
        }else if(videoUrl != ""){
            return (
                <VideoStream
                    url={videoUrl}
                    videoEnded={props.videoEnded}
                    videoTime={props.videoTime}
                    sendCurrentVideoQuestionTime={ props.sendCurrentVideoQuestionTime}
                />
            );
        }else {
            return <div style={{height:"100vh",width:"100vh",backgroundColor:"black"}}></div>
        }
    }
}
