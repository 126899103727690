import React,{useEffect, useState}from "react";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Countdown from "react-countdown";
import moment from "moment";
import defaultBg from "assets/img/QUIZ-Connect-Grafik_QC-Screen-BG-standard.png"
import Container from "@material-ui/core/Container";

import eventLogo from "assets/img/reactlogo.png"

import quizConnectLogo from "assets/img/QUIZ-Connect-Grafik_Quiz-Connect-Logo.png"
import QuizContainer from "../../components/Quiz/QuizContainer";

export default function Over(props) {

    const [eventStart, setEventStart] = useState("")

    return(
        <QuizContainer

            background={defaultBg}
            eventStart={eventStart}
            eventLogo={props.eventLogo}
            quizConnectLogo={quizConnectLogo}
        >
            <GridItem xs={12} style={{margin:"0 auto", textAlign:"center", color:"#fff", fontSize:"2em"}}>
                <p style={{fontWeight:"700",fontSize:"1.8em",lineHeight: "1.1em", margin:"10px 0 40px 0"}}>
                    Das Quiz ist vorbei. Wir hoffen es hat gefallen!
                </p>
            </GridItem>
        </QuizContainer>
    )
}