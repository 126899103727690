import React from 'react'

import GridContainer from "components/Grid/GridContainer.js";


export default function Questions(props) {


    //const [question, setQuestion] = useState([])

    // let {questionId} = props.match.params;
    //const qestionUrl = "/Question/" + questionId

    return (
        <GridContainer>
        </GridContainer>
    );
}
