import React from "react";

import Countdown from "react-countdown";
import moment from "moment";

export default function QuizContainer(props){
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if(props.showHours){
            return(
                <p className={"quiz-header countdown-header"} >
                    {hours} Stunden - {minutes} Minuten - {seconds} Sekunden
                </p>
            )
        }
        return(
                <p className={"quiz-header countdown-header"} >
                    {minutes + (hours * 60)} Minuten - {seconds} Sekunden
                </p>
            )
    };

    const timerEnd = ()=>{
        if(  props.timerEnds ){
            props.timerEnds();
        }else{
            window.location.reload();
        }
    }

    let mainDivStyles = {
        backgroundImage:"URL('" + props.background + "')",
    }
    if(props.theme){
        mainDivStyles = {
            ...mainDivStyles,
            backgroundImage:"URL('" + props.theme + "')"
        }
    }else{
        mainDivStyles = {
            ...mainDivStyles,
            backgroundImage:"URL('" + props.background + "')"
        }
    }
    if(props.backgroundColor){
        mainDivStyles = {
            ...mainDivStyles,
            backgroundColor:props.backgroundColor
        }
    }

    return(
        <div style={mainDivStyles} className={"gridContainer"} id={"quiz-container"}>
            <div id={"quiz-container-wrapper"}>
                    {props.eventStart &&
                        <div style={{margin:"0 auto", textAlign:"center"}}>
                            <Countdown date={ moment(props.eventStart).unix() * 1000  }  renderer={renderer}  onComplete={timerEnd} />
                        </div>
                    }

                    {props.eventLogo &&
                        <div style={{margin:"1vh auto", textAlign:"center"}}>
                            <img className={"companyBrand"} src={props.eventLogo} />
                        </div>
                    }

                    {props.children}

                    {props.quizConnectLogo &&
                        <div style={{margin:"0 auto", height: "25%", display:"flex",justifyContent:"center"}}>
                            <img className={"quiz-branding"} src={props.quizConnectLogo} style={{width:"auto",height: "100%"}}/>
                        </div>
                    }
            </div>
                </div>
    )
}
