import React, {useState, useEffect} from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter";

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Button from "components/CustomButtons/Button.js";

import fetcher from "services/Fetcher.js"
import {Link} from "react-router-dom";

export default function Users(props) {

    const [users, setUsers] = useState([] )
    
    useEffect( () => {
        fetcher("users")
            .then(resp => resp.json())
            .then(data => setUsers(data) )
    },[])

    return(
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="primary">
                        <h4>Benutzer</h4>
                    </CardHeader>
                    <CardBody>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            E-Mail
                        </TableCell>
                        <TableCell>
                            Bearbeiten
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map((value, index) => {
                        return(
                            <TableRow key={index}>
                                <TableCell>
                                    {value.email}
                                </TableCell>
                                <TableCell>
                                    <Link to={"/admin/usersEdit/" + value.id}>
                                        <Button color="primary">
                                            Bearbeiten
                                        </Button>
                                    </Link>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
                    </CardBody>
                    <CardFooter>
                        <Link to={"/admin/usersEdit/new"}>
                            <Button color="primary">
                                Neuer Benutzer
                            </Button>
                        </Link>
                    </CardFooter>
                </Card>
            </GridItem>
        </GridContainer>
    )
}