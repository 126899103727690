import React, {useEffect, useState} from 'react';
import Hls from "hls.js";

export default function VideoStream({url, videoEnded, videoTime, sendCurrentVideoQuestionTime}){

    const [player, setPlayer] = useState(null);

    React.useEffect(() => {
        const videoElement = document.getElementById("videoPlayer");
        const wrapper = document.getElementById("videoPlayer_wrapper");
        const hls = new Hls({
            debug: false,
        });
        if (Hls.isSupported()) {

            hls.loadSource(url);
            hls.attachMedia(videoElement);
            hls.on(Hls.Events.MEDIA_ATTACHED, function () {
                videoElement.onended = function () {
                    videoElement.classList.add("invisible");
                    wrapper.classList.add("invisible");
                    hls.destroy()
                    videoEnded();
                };
                videoElement.onerror = function () {
                    videoEnded();
                }
                videoElement.muted = false;
                videoElement.currentTime = videoTime ?? 0;
                let loadedMetadata;
                loadedMetadata = function (event) {
                    videoElement.currentTime = videoTime;
                    videoElement.removeEventListener("loadedmetadata", loadedMetadata);
                }
                if (videoElement.currentTime !== videoTime) {
                    videoElement.addEventListener("loadedmetadata", loadedMetadata);
                }
                videoPlayer.classList.remove("invisible");
                wrapper.classList.remove("invisible");
                videoElement.play();
            });

        } else {

            videoElement.src = url;

            videoElement.load();
            videoElement.pause();

            videoElement.currentTime = videoTime ?? 0;
            let loadedMetadata;
            loadedMetadata = function (event) {
                videoElement.currentTime = videoTime;
                videoElement.removeEventListener("loadedmetadata", loadedMetadata);
            }
            if (videoElement.currentTime !== videoTime) {
                videoElement.addEventListener("loadedmetadata", loadedMetadata);
            }

            videoElement.muted = false;

            videoElement.onended = function () {

                videoElement.classList.add("invisible");
                wrapper.classList.add("invisible");
                videoEnded();
            };
            videoElement.onerror = function () {
                videoElement.classList.add("invisible");
                wrapper.classList.add("invisible");
                videoEnded();
            }
            videoElement.classList.remove("invisible");
            wrapper.classList.remove("invisible");
            videoElement.play();
        }

        setPlayer(videoElement)

        return ()=>{
            if(hls) hls.destroy()
        }
    }, [url]);

    useEffect(()=>{

        let interval=null;
        if(player != null){
            interval = setInterval(() => {
                sendCurrentVideoQuestionTime(player.currentTime);
            }, 1000);
        }

        return () => {
            if(interval!=null) clearInterval(interval);
        };

    },[player])


    return (
        <div style={{height: "100vh", width: "100%", backgroundColor: "black"}}>
        </div>
    );
}
