import GridContainer from "../../components/Grid/GridContainer";
import React, {useEffect, useState} from "react";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import GridItem from "../../components/Grid/GridItem";
import fetcher from "../../services/Fetcher";
import CardHeader from "../../components/Card/CardHeader";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {TableBody} from "@material-ui/core";
import moment from "moment";
import Button from "../../components/CustomButtons/Button";
import TextField from "@material-ui/core/TextField";
import {Link} from "react-router-dom";

export default function MissionControl(props) {

    const [quizRun, setQuizRun] = useState(null)
    const [event, setEvent] = useState({})

    useEffect(() => {

        fetcher("Event/" + props.match.params.eventId)
            .then(result => result.json())
            .then((res) => {
                setEvent(res)
            })
        const interval = setInterval(() => {
            fetcher("quizrun/control/" + props.match.params.eventId)
                .then(async result => {
                    if(result.status == "200"){
                        let json = await result.json();
                        setQuizRun(json)
                    }else{
                        setQuizRun(null)
                    }
                })
        }, 1000)

        return ()=>{
            clearInterval(interval)
        }
    }, [])

    const stopQuiz = () => {

        if(!window.confirm("Quiz beenden?")) return

        fetcher("Event/" + event.id + "/stop", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then(r => r.json())
            .then(() => {
                setEvent({
                    ...event,
                    isRunning : false
                })
            })
    }

    const forceStartEvent = () => {
        if (window.confirm("Event manuel starten? Wir starten in 5 Sekunden!")) {
            fetcher("Event/" + props.match.params.eventId + "/force", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }).then(()=>{
                setEvent({
                    ...event,
                    isRunning :true
                })
            })
        }
    }

    const forceOpenLobby = () => {
        if (window.confirm("Lobby manuell öffnen? Event startet 5 Minuten später!")) {
            fetcher("Event/" + props.match.params.eventId + "/lobby", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                }
            })
        }
    }

    const forceOutro = () => {
        if (window.confirm("Outro Erzwinerzwingengen?")) {
            fetcher("quizrun/" + props.match.params.eventId + "/outro", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
        }
    }

    const forceFinish = () => {
        if (window.confirm("Score erzwingen?")) {
            fetcher("quizrun/" + props.match.params.eventId + "/finish", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
        }
    }

    const forceNext = () => {
        if (window.confirm("Nächste Frage?")) {
            fetcher("quizrun/" + props.match.params.eventId + "/next", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
        }
    }

    const checkQuiz = () => {
            fetcher("quizrun/" + props.match.params.eventId + "/check", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then(res => res.json())
                .then(res => {
                    alert(JSON.stringify(res))
                })
    }
    var full = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');


    return (
        <GridContainer>
            <Link to={"/admin/events/"  }>
                <Button color={"primary"}>Zurück</Button>
            </Link>
            <Card>
                <CardBody>
                    <GridContainer>
                        <GridItem xs={12}>
                            {event.withQuizLeader &&
                                <h1>Offline Event: {event.name}</h1>
                            }
                            {event.withQuizLeader == false &&
                                <h1>Online Event: {event.name}</h1>
                            }
                        </GridItem>
                        <GridItem xs={3}>
                            <Button size={"sm"} style={{border: "solid 1px #ccc"}} color="transparent" type={"button"}
                                    onClick={e => checkQuiz()}>
                                Quiz Prüfen
                            </Button>
                        </GridItem>
                        {quizRun == null &&
                        <GridItem xs={3}>
                            <Button size={"sm"} style={{border: "solid 1px #ccc"}} color="transparent" type={"button"}
                                    onClick={e => forceOpenLobby()}>
                                Lobby eröffnen
                            </Button>
                        </GridItem>
                        }

                        {event.withQuizLeader &&
                        <GridItem xs={3}>
                            <TextField
                                style={{display: "inline", width: "100px", border: "none"}}
                                id={"quizLeaderPassword"}
                                value={event.quizLeaderPassword || ""}
                            />
                            <Button
                                size={"sm"} style={{border: "solid 1px #ccc"}} color="transparent"
                                onClick={() => {
                                    const copyText = document.querySelector("#quizLeaderPassword");
                                    copyText.select();
                                    document.execCommand("copy");
                                }}>
                                Passwort kopieren
                            </Button>
                        </GridItem>
                        }
                        {event.withQuizLeader &&
                            <GridItem xs={3}>
                                <Button
                                    color={"info"}
                                    size={"sm"}
                                    href={full + "/playground/quiz/" + props.match.params.eventId + "?quizmaster=1"}
                                    target={"_blank"}
                                    rel="noopener noreferrer">
                                    Quizmaster öffnen
                                </Button>
                            </GridItem>
                        }

                        <GridItem xs={3}>
                            <Button
                                style={{border: "solid 1px #ccc"}}
                                color={"transparent"}
                                size={"sm"}
                                href={full + "/playground/quiz/" + props.match.params.eventId }
                                target={"_blank"}
                                rel="noopener noreferrer">
                                Spielerlink öffnen
                            </Button>
                        </GridItem>


                        {quizRun != null && quizRun.currentRunState.state == "lobby" &&
                                <GridItem xs={3}>
                                    <Button size={"sm"} style={{border: "solid 1px #ccc"}} color="primary" type={"button"}
                                            onClick={e => forceStartEvent()}>
                                        Quiz starten
                                    </Button>
                                </GridItem>

                        }

                        {quizRun != null &&
                        <GridItem xs={3}>
                            <Button size={"sm"} style={{border: "solid 1px #ccc"}} color="primary" type={"button"}
                                    onClick={e => stopQuiz()}>
                                Event beenden
                            </Button>
                        </GridItem>
                        }


                    </GridContainer>
                </CardBody>
            </Card>

            <Card>
                <CardBody>
                    <GridContainer>
                        <GridItem xs={3}>
                            <Button size={"sm"} style={{border: "solid 1px #ccc"}} color="danger" type={"button"}
                                    onClick={e => forceNext()}>
                                Nächsten Status erzwingen
                            </Button>
                        </GridItem>
                        <GridItem xs={2}>
                            <Button size={"sm"} style={{border: "solid 1px #ccc"}} color="danger" type={"button"}
                                    onClick={e => forceOutro()}>
                                Outro erzwingen
                            </Button>
                        </GridItem>

                        <GridItem xs={2}>
                            <Button size={"sm"} style={{border: "solid 1px #ccc"}} color="danger" type={"button"}
                                    onClick={e => forceFinish()}>
                                Score erzwingen
                            </Button>
                        </GridItem>
                    </GridContainer>

                </CardBody>
            </Card>

            {quizRun != null &&
            <React.Fragment>
                <Card>
                    <CardHeader>
                        <h4>{quizRun.event.name}</h4>
                    </CardHeader>
                    <CardBody>
                        <GridContainer>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            Status
                                        </TableCell>
                                        <TableCell>
                                            {quizRun.currentRunState.state}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            Eigenschaften
                                        </TableCell>
                                        <TableCell>
                                            <ul>
                                                {quizRun.currentRunState.properties.map(
                                                    (state) => {
                                                        return (
                                                            <li key={state.key}>
                                                                {state.key} :
                                                                {typeof state.value == "string" &&
                                                                <span>{state.value}</span>
                                                                }
                                                            </li>
                                                        )
                                                    }
                                                )}
                                            </ul>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </GridContainer>
                    </CardBody>
                </Card>
                <Card>
                    <CardHeader>
                        <h2>Members</h2>
                    </CardHeader>
                    <CardBody>
                        <GridContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            Name
                                        </TableCell>
                                        <TableCell>
                                            Aktueller Status
                                        </TableCell>
                                        <TableCell>
                                            Daten
                                        </TableCell>
                                        <TableCell>
                                            Letze Aktion
                                        </TableCell>
                                        <TableCell>
                                            Connection
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {quizRun.members.map(
                                        member => {
                                            return (
                                                <TableRow>
                                                    <TableCell>
                                                        {member.name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {member.memberStateView != null &&
                                                        <span>
                                                            {member.memberStateView.memberView}
                                                        </span>
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        {member.memberStateView != null &&
                                                        <ul>
                                                            {member.memberStateView.properties.map(
                                                                pro => {
                                                                    return (
                                                                        <li key={pro.key + "_" + member.id}>
                                                                            {pro.key} :
                                                                            {typeof pro.value == "string" &&
                                                                            <span>{pro.value}</span>
                                                                            }
                                                                        </li>
                                                                    )
                                                                }
                                                            )}
                                                        </ul>
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        {member.connectionStatus != 0 &&
                                                        <span>
                                                            {moment(member.lastStateChangedTime).format("HH:mm:ss")}
                                                        </span>
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        {member.connectionStatus == 0 &&
                                                        <span>Connected</span>
                                                        }
                                                        {member.connectionStatus != 0 &&
                                                        <span>
                                                            <span
                                                                style={{color: "red"}}>X</span> {moment(member.disconnectionTime).format("HH:mm:ss")}
                                                        </span>
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }
                                    )}
                                </TableBody>
                            </Table>
                        </GridContainer>
                    </CardBody>
                </Card>
            </React.Fragment>
            }


        </GridContainer>
    )
}
