import React, {useEffect, useState} from "react";
import fetcher from "../../services/Fetcher";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import {Button, Table} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import {CheckCircleOutline, ThumbDown} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-react/components/tasksStyle";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import StarRatings from "react-star-ratings";
import QuizContainer from "../../components/Quiz/QuizContainer";
import bg from "../../assets/img/QUIZ-Connect-Grafik_QC-Screen-BG-Fehler.png";
const useStyles = makeStyles(styles);

export default function ResultDetails({selection, back}) {

    const classes = useStyles();

    const [result, setResult] = useState(null);

    useEffect(() => {
        fetcher("quiz/finished?selection=" + encodeURIComponent(selection))
            .then(r => r.json())
            .then(res => {
                setResult(res);
            })
    }, []);

    if (!result) {
        return <div>Loading ...</div>
    }
    console.log(result)
    return (
        <Card>
            <Button onClick={back}>Zurück</Button>
            <CardHeader color="primary">
                <h2>Quiz {result.run.summary.name}</h2>
                <h4>{result.run.summary.started}</h4>
                <h4>{result.run.summary.memberCount} Spielern</h4>
            </CardHeader>
            <CardBody>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <h2>Gewinner</h2>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Platz</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>E-Mail</TableCell>
                                    <TableCell>Richtige Antworten</TableCell>
                                    <TableCell> &#x00D8; Zeit</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {result.data.winners.map( (r, i) => (
                                    <TableRow key={i}>
                                        <TableCell>{r.placement}</TableCell>
                                        <TableCell>{r.name}</TableCell>
                                        <TableCell>{r.mail}</TableCell>
                                        <TableCell>{r.score}</TableCell>
                                        <TableCell>{r.averageTime / 1000}Sek. &#x00D8;</TableCell>
                                    </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </GridItem>
                </GridContainer>


                <GridContainer>
                    <GridItem xs={12} sd={12} md={12}  key={"member_header"}>
                        <h2>Alle Teilnehmer</h2>
                    </GridItem>
                        {result.run.memberList.map((member, index) =>
                            <GridItem xs={12} sd={6} md={6}  key={"member_" + index} style={{border:"solid 1px #f2f2f2"}}>
                                <span>{member.name} (Score: {member.score})</span><br />
                                <span>
                            Quizbewertung:
                                <StarRatings
                                    stopColorStyle={"#ff9800"}
                                    starRatedColor="#ff9800"
                                    starHoverColor={"#ff9800"}
                                    rating={member.rating}
                                    changeRating={()=>{}}
                                    starDimension="5vw"
                                    numberOfStars={5}
                                    name='rating'
                                />
                            </span><br />
                                <Table className={classes.table} >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{width:"10%"}}>
                                            </TableCell>
                                            <TableCell style={{width:"30%"}}>
                                                Frage
                                            </TableCell>
                                            <TableCell style={{width:"30%"}}>
                                                Antwort
                                            </TableCell>
                                            <TableCell style={{width:"30%"}}>
                                                Richtig?
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {member.questionResults.map((result, index)=>
                                            <TableRow key={"member_row_" + index}>
                                                <TableCell>
                                                    {index + 1}
                                                </TableCell>
                                                <TableCell>
                                                    {result.questionText}
                                                </TableCell>
                                                <TableCell>
                                                    {result.answerText}
                                                </TableCell>
                                                <TableCell>
                                                    {result.correct &&
                                                        <CheckCircleOutline style={{color:"green"}} />
                                                    }
                                                    {!result.correct &&
                                                        <ThumbDown style={{color:"red"}} />
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>

                                </Table>
                            </GridItem>
                        )}
                </GridContainer>
            </CardBody>
        </Card>
    )
}
