import React, {useState} from "react";
import {useEffect} from "react";
import fetcher from "../../services/Fetcher";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import Button from "../../components/CustomButtons/Button";
import CardFooter from "../../components/Card/CardFooter";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import {Link} from "react-router-dom";
import CardHeader from "../../components/Card/CardHeader";

export default function Sound(props){
    const [sounds, setSounds] = useState([]);

    useEffect(()=>{
        fetcher("sound")
            .then(r => r.json())
            .then(r => setSounds(r) )
    },[])

    const deleteSound = (sound) => {
        if(window.confirm("Sound wirklich löschen?")){
            fetcher("sound/" + sound.id, {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then(r => r.json())
                .then(r => setSounds(r))
        }
    }

    const createSoundRows = ()=>{
        return sounds.map((value, index) => {
            return(
                <TableRow key={ "sounds_" + index }>
                    <TableCell>
                        {value.name}
                    </TableCell>
                    <TableCell>
                        {value.bgSound &&
                            "Ja"
                        }
                    </TableCell>
                    <TableCell>
                        {value.createdAt}
                    </TableCell>
                    <TableCell>
                        <Button onClick={ () => deleteSound(value) } color={"warning"} > x </Button>
                    </TableCell>
                </TableRow>
            )
        })
    }

    return(
        <Card>
            <CardHeader>
                <h1>Sounds</h1>
            </CardHeader>
            <CardBody>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Name
                            </TableCell>
                            <TableCell>
                                Hintergrund
                            </TableCell>
                            <TableCell>
                                Erstellt am
                            </TableCell>
                            <TableCell>
                                Löschen
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {createSoundRows()}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell>
                                <Link to={"/admin/sounds/add"}>
                                    <Button color={"primary"}>Neuer Sound</Button>
                                </Link>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </CardBody>
        </Card>
    )

}
