
import React, {useState, useEffect} from "react";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import TextField from "@material-ui/core/TextField";
import CardFooter from "components/Card/CardFooter.js";
import Redirect from "react-router-dom/Redirect";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import fetcher from "services/Fetcher.js"

import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import {DropzoneArea} from "material-ui-dropzone";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";
import QuestionBrowser from "../../components/Question/QuestionBrowser";
import moment from "moment";

export default function EventEdit(props) {

    let today = new Date();
    let h_event = today.getHours() + 2 ;

    if( (h_event + "").length === 1){
        h_event = "0" + h_event;
    }

    let h_lobby = today.getHours() + 1 ;

    if( (h_lobby + "").length === 1){
        h_lobby = "0" + h_lobby;
    }


    let dateEvent = today.toISOString().substr(0, 10) + "T" + h_event + ":00";
    let dateLobby = today.toISOString().substr(0, 10) + "T" + h_lobby + ":00";

    const [event, setEvent] = useState({
        eventStart: dateEvent,
        lobbyStart: dateLobby,
        telefone: "",
        name: "",
        email: "",
        firstName: "",
        lastName: "",
        questions: [],
        eventLogoUrl : "",
        eventOutroUrl:"",
        eventLogoFile:null,
        eventIntroFile: null,
        eventOutroFile: null,
        eventIntroUrl:"",
        maxWaitingTime:10,
        answerTimeSeconds:20,
        scoreViewTime:10,
        withQuizLeader : false,

        eventIntroName : "",
        eventOutroDuration : "",
        eventIntroDuration : "",
        eventOutroName : ""

    })
    const [uploading, setUploading] = useState(false);

    const [questions, setQuestions] = useState([])

    const [directories, setDirectories] = useState([])
    const [directory, setDirectory] = useState("")
    const [currentDirectory, setCurrentDirectory] = useState(null)

    const [redirectURL, redirect] = useState("");

    let eventId = (props.match.params.eventId !== "new" && props.match.params.eventId !== undefined) ? props.match.params.eventId : "";

    const loadCurrentDirectory = ()=>{
        if(directory){
            fetcher("directory/" + directory)
                .then(resp => resp.json())
                .then((data) => {
                    console.log("loadCurrentDirectory", data)
                    setCurrentDirectory(data)
                })
        }else{
            setCurrentDirectory(null)
        }
    }
    useEffect(loadCurrentDirectory, [directory])

    const loadDirectories = ()=>{
        fetcher("directory" + "?parentId=" + directory)
            .then(resp => resp.json())
            .then((data) => {
                console.log("loadDirectories", data)
                setDirectories(data)
            })
    }
    useEffect(loadDirectories, [directory])

    const loadQuestions = () => {
        fetcher("question" + "?directoryId=" + directory)
            .then(resp => resp.json())
            .then((data) => {
                setQuestions(data)
            })
    }
    useEffect(loadQuestions, [directory])

    useEffect(() => {
        const fetchData = async() => {

            eventId = props.match.params.eventId;
            if (eventId === "new") {
                eventId = null;
            }
            if (eventId) {
                const response = await fetcher("Event/" + eventId);
                let data = await response.json();
                setEvent(data);
            }
        }
        fetchData();
    }, []);

    const questionSelectionChanged = (question) => {

        const questions = event.questions;
        questions.push({
            position: event.questions.length,
            question: question
        })

        const selection = questions
            .sort((a, b) => a.position > b.position ? 1 : -1)
            .map((value, index)=>{
            return {
                ...value,
                position: index
            }
        })
        setEvent({
            ...event,
            questions: selection
        })

    }
    const inputChanged = (e)=>{
        let val =  e.target.value;
        if(e.target.type == "checkbox"){
            val = e.target.checked
        }
        if(e.target.type == "number" && val != ""){
            val = parseInt(val)
        }
        if(e.target.type == "datetime-local" && val != ""){
            val = moment(val).toISOString(true)
        }
        setEvent({
            ...event,
            [e.target.name] : val
        })
    }

    const save = () => {
        const data = JSON.stringify(event)
        let url = "Event/";

        setUploading(true);

        let eventId = props.match.params.eventId;
        if (eventId === "new") {
            eventId = null;
        }else{
            url += eventId;
        }

        fetcher(url, {
            method: "POST",
            body: data,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then(resp => resp.json())
            .then((data)=>{
                    uploadFiles(data).then( () => {
                        redirect("/admin/events")
                    })
                })
    }

    const uploadFiles = (data) => {
        return new Promise(
            function(resolve, reject) {
                if ( !data.id || (event.eventIntroFile == null && event.eventLogoFile == null && event.eventOutroFile == null)) {
                    resolve({success:false, message:"no_id"});
                }

                uploadFile(data.id, event.eventLogoFile, "logo").then(()=>{
                    uploadFile(data.id, event.eventIntroFile, "intro").then(()=>{
                        uploadFile(data.id, event.eventOutroFile, "outro").then(()=>{
                            resolve({success:true, message:"done"});
                        })
                    })
                });
            });
    };

    if(redirectURL !== ""){
        return(
            <Redirect to={redirectURL} />
        )
    }

    if(uploading){
        return (
            <GridContainer>
                <GridItem xs={12} style={{textAlign:"center", color:"black"}}>
                    <CircularProgress />
                    <p >Alles wird gespeichert ....</p>
                </GridItem>
            </GridContainer>
        )
    }

    const columns = [
        { selector: 'text', name: 'Frage', sortable: true },
        //{ selector: 'id', name: 'ID',sortable: false,style:{display:"none"} },
        { selector: 'status', name: 'Status', sortable: true, },
        { selector: 'category', name: 'Kategorie', sortable: true },
        { selector: 'date', name: 'Zu letzt geändert am:', sortable: true },
    ];

    const uploadFile = (id,file,url)=>{
        return new Promise(
            function(resolve, reject) {
                if (file == null) {
                    resolve({success:false, message:"file is null"})
                }
                let formData = new FormData();
                formData.append(
                    "files",
                    file,
                );
                fetcher("Event/" + id + "/" + url, {
                    method: "POST",
                    body: formData,
                }).then(() => resolve({success:true, message:"ok"}) )
            })
    }
    const questionSelected = (questionId) => {
        let result =  event.questions.find( (x) => {
            if(x.question.id === questionId){
                return x;
            }
            return undefined;
        } )
        return result !== undefined;
    }

    const removeQuestion = (questionId)=>{
        console.log( event.questions)
        let result =  event.questions.filter( (x) => {
            return  x.question.id !== questionId
        });

        const selection = result
            .sort((a, b) => a.position > b.position ? 1 : -1)
            .map((value, index)=>{
                return {
                    ...value,
                    position: index
                }
            })

        setEvent({
            ...event,
            questions: selection
        })
    }

    const SortableItem = SortableElement(({text, category, positionText, questionId}) => {
        return(
            <ListItem style={{borderTop:"solid 1px #ccc"}}>
                <ListItemText primary = {positionText + text} secondary = {category} />
                <button className={"button button-danger"} onClick={ () => removeQuestion(questionId) } color={"danger"}>X</button>
            </ListItem>
        );
    })

    const SortableList = SortableContainer(({items}) => {
        return (
            <List>
                {items
                    .sort( (a,b )=> a.position > b.position ? 1 : -1  )
                    .map((value, index) =>
                        <SortableItem
                            distance={1}
                            pressDelay={2}
                            key={`item-${index}`}
                            index={index}
                            category={value.question.category}
                            positionText={(value.position + 1) + ". Frage: "}
                            questionId={value.question.id}
                            text={value.question.text} />
                    )
                }
            </List>
        );
    });

    const onSortEnd = ({oldIndex, newIndex}) => {

        let movedElement =  event.questions[oldIndex];
        console.log(movedElement)
        event.questions.map( (q, index) => {

            if(q.position == newIndex){
                movedElement.position = newIndex;
            }
            if(q.position > newIndex){
                q.position= index + 1;
            }
        })

        const sorted = event.questions.sort( (a,b) => {
            return a.position > b.position ? 1 : -1;
        })

        sorted.map( (a,b) => {
            a.position = b;
        })

        setEvent({
            ...event,
            questions: sorted
        })
    }

    const handleLogoChanged = (files)=>{
        setEvent({
            ...event,
            eventLogoFile: files[0],
        });
    }

    const handleIntroChanged = (files)=>{
        setEvent({
            ...event,
            eventIntroFile: files[0],
        });
    }
    const handleOutroChanged = (files)=>{
        setEvent({
            ...event,
            eventOutroFile: files[0],
        });
    }


    const deleteEventIntro = ()=>{
        setEvent({
            ...event,
            eventIntroFile: null,
            eventIntroUrl: ""
        });
    }

    const deleteEventOutro = ()=>{
        setEvent({
            ...event,
            eventOutroFile: null,
            eventOutroUrl: ""
        });
    }

    const deleteEventLogo = ()=>{
        setEvent({
            ...event,
            eventLogoFile: null,
            eventLogoUrl: ""
        });
    }

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="primary">
                        <h4>Neues Event</h4>
                    </CardHeader>
                </Card>
                <Card>
                    <CardBody>
                        <GridContainer>
                            <GridItem>
                                <label>Public Viewing Modus</label>
                                <Checkbox
                                    name={"withQuizLeader"}
                                    checked={event.withQuizLeader}
                                    tabIndex={-1}
                                    onClick={ (e)=>  inputChanged(e) }
                                />

                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <h4>Planung</h4>
                                <TextField
                                    fullWidth
                                    name={"name"}
                                    value={event.name  || "" }
                                    onChange={ (e)=> inputChanged(e)}
                                    variant="outlined"
                                    margin={"normal"}
                                    label="Event Name" />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                                <TextField
                                    fullWidth
                                    onChange={ (e)=> inputChanged(e)}
                                    margin={"normal"}
                                    variant="outlined"
                                    type="datetime-local"
                                    value={ moment(event.eventStart).format("YYYY-MM-DDTHH:mm")  || "2021-01-01T00:00" }
                                    name={"eventStart"}
                                    label="Event Start"/>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                                <TextField
                                    fullWidth
                                    value={ moment(event.lobbyStart).format("YYYY-MM-DDTHH:mm")  || "2021-01-01T00:00" }
                                    name={"lobbyStart"}
                                    onChange={ (e)=> inputChanged(e)}
                                    type="datetime-local"
                                    margin={"normal"}
                                    variant="outlined"
                                    label="Lobby Start"/>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                    <h4>Ansprechpartner</h4>
                            </GridItem>

                            <GridItem xs={6} sm={6} md={6}>
                                    <TextField
                                        fullWidth
                                        value={event.firstName}
                                        name={"firstName"  || "" }
                                        onChange={ (e)=> inputChanged(e)}
                                        margin={"normal"}
                                        variant="outlined"
                                        label="Vorname"/>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                                    <TextField
                                        fullWidth
                                        value={event.lastName}
                                        name={"lastName"  || "" }
                                        onChange={ (e)=> inputChanged(e)}
                                        margin={"normal"}
                                        variant="outlined"
                                        label="Nachname"/>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                                    <TextField
                                        fullWidth
                                        value={event.telefone}
                                        name={"telefone"  || "" }
                                        onChange={ (e)=> inputChanged(e)}
                                        margin={"normal"}
                                        variant="outlined"
                                        label="Telefon" />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                                    <TextField
                                        fullWidth
                                        value={event.email}
                                        name={"email"  || "" }
                                        onChange={ (e)=> inputChanged(e)}
                                        margin={"normal"}
                                        variant="outlined"
                                        label="E-Mail"/>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <h4>Konfigurationen</h4>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                                <TextField
                                    type={"number"}
                                    fullWidth
                                    value={event.maxWaitingTime}
                                    name={"maxWaitingTime"}
                                    onChange={ (e)=> inputChanged(e)}
                                    margin={"normal"}
                                    variant="outlined"
                                    label="Wie viele Sekunden warten wir auf den Spieler?"/>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                                <TextField
                                    type={"number"}
                                    fullWidth
                                    value={event.answerTimeSeconds}
                                    name={"answerTimeSeconds"}
                                    onChange={ (e)=> inputChanged(e)}
                                    margin={"normal"}
                                    variant="outlined"
                                    label="Antwortzeit (Sekunden)"/>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                                <TextField
                                    type={"number"}
                                    fullWidth
                                    value={event.scoreViewTime}
                                    name={"scoreViewTime"}
                                    onChange={ (e)=> inputChanged(e)}
                                    margin={"normal"}
                                    variant="outlined"
                                    label="Siegerehrung Ansicht (Sekunden)"/>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <GridContainer>
                        <GridItem xs={6} sm={6} md={6}>
                            <div className="answerers">
                                <h4>Intro Video ({event.eventIntroDuration})</h4>
                                <DropzoneArea
                                    onChange={handleIntroChanged}
                                    dropzoneText="Intro hier rein!"
                                    maxFileSize={999999999999999999}
                                />
                            </div>
                            {event.eventIntroUrl && (
                                <div>
                                    {event.eventIntroUrl  && (
                                        <Button size="sm" target={"_blank"} variant="contained" color="info" href={event.eventIntroUrl}>
                                            Öffnen {event.eventIntroName}
                                        </Button>
                                    )}
                                    <Button
                                        size="sm"
                                        color="warning"
                                        onClick={deleteEventIntro}
                                    >
                                        Intro entfernen!
                                    </Button>
                                </div>
                            )}
                         </GridItem>

                            <GridItem xs={6} sm={6} md={6}>
                                <div className="answerers"><h4>Outro Video ({event.eventOutroDuration})</h4>

                                    <DropzoneArea
                                        onChange={handleOutroChanged}
                                        dropzoneText="Outro hier rein!"
                                        maxFileSize={999999999999999999}
                                    />
                                </div>
                                {event.eventOutroUrl && (
                                    <div>
                                        {event.eventOutroUrl  && (
                                            <Button size="sm" target={"_blank"} variant="contained" color="info" href={event.eventOutroUrl}>
                                                Öffnen {event.eventOutroName}
                                            </Button>
                                        )}
                                        <Button
                                            size="sm"
                                            color="warning"
                                            onClick={deleteEventOutro}
                                        >
                                            Outro entfernen!
                                        </Button>
                                    </div>
                                )}
                            </GridItem>

                        </GridContainer>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <GridItem xs={12} sm={12} md={12}>
                            <div className="answerers">
                                <h4>Fragen sortieren</h4>
                                <SortableList hideSortableGhost={true} axis="y" lockAxis="y" items={event.questions} onSortEnd={onSortEnd} />
                            </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <div style={{ height: '50vh', width: '100%',     overflow: "auto"}}>
                                <h4>Fragen wählen {currentDirectory &&  <span>aus {currentDirectory.name}</span> }</h4>
                                <QuestionBrowser
                                    selectQuestion = { (question) => questionSelectionChanged(question)}
                                    questionSelected={(id) => questionSelected(id)}
                                    canEdit={false}
                                    directoryChange={ (directory) => {
                                        console.log(directory)
                                        setDirectory(directory)
                                    } }
                                    from={"/admin/EventEdit/" + eventId + "/"}
                                    directories={directories}
                                    currentDirectory={currentDirectory}
                                    questions={questions}
                                    directory={currentDirectory}
                                />

                            </div>
                        </GridItem>
                    </CardBody>
                </Card>
            </GridItem>

            <GridItem xs={12}>
                <Card>
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12}>
                                <h4>Event Logo</h4>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>

                                <DropzoneArea
                                    onChange={handleLogoChanged}
                                    dropzoneText="Logo hier rein!"
                                    maxFileSize={999999999999999999}
                                />

                                {event.eventLogoUrl && (
                                    <Button
                                        size="sm"
                                        color="warning"
                                        onClick={deleteEventLogo}
                                    >
                                        Logo entfernen!
                                    </Button>
                                )}
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>

                                {event.eventLogoUrl  && (
                                    <img style={{width:"50%"}} src={event.eventLogoUrl} />
                                )}

                            </GridItem>
                        </GridContainer>

                    </CardBody>

                    <CardFooter>
                        <Button color={"primary"} onClick={save}>Speichern</Button>
                    </CardFooter>
                </Card>

            </GridItem>

        </GridContainer>



    );
}
