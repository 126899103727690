import React, {useState} from 'react'

// @material-ui/core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import fetcher from "../../services/Fetcher";
import Redirect from "react-router-dom/Redirect";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";

export default function EventDelete(props) {

    const [redirectURL, redirect] = useState("");
    const deleteEvent = () => {
        fetcher("event/" + props.match.params.eventId, {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then( () => {
                redirect("/admin/events")
            } )
    }

    if(redirectURL !== ""){
        return(
            <Redirect to={redirectURL} />
        )
    }

    return (
        <Card>
            <CardBody>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <h3>
                            Möchten Sie dieses Event wirklich löschen?
                        </h3>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <Button color={"warning"} onClick={deleteEvent}>Event Löschen</Button>
                    </GridItem>
                </GridContainer>
            </CardBody>
        </Card>
    );
}
