import React, {useEffect, useState} from "react";
import QuizContainer from "../../components/Quiz/QuizContainer";
import defaultBg from "../../assets/img/QUIZ-Connect-Grafik_QC-Screen-BG-standard.png";
import NoSleep from 'nosleep.js';
import fetcher from "../../services/Fetcher"
import WaitingView from "./WaitingView";
export default function MemberJoin(props) {

    const [eventStore, setEventStore] = useState(null)
    const [memberCount, setMemberCount] = useState(-1)
   
   const loadUser = ()=>{
       console.log("load users " + props.eventId)
       fetcher("quiz/event/" + props.eventId + "/users")
       .then(resp => resp.json())
       .then(list => {
           setMemberCount(list.count)
       })
   }
   useEffect(()=>{
       loadUser()
       const i = setInterval(loadUser,3000)
       return ()=> clearInterval(i)
   },[])

    useEffect(()=>{
        const e = localStorage.getItem("eventStore")
        setEventStore(e)
    }, [])

    const canFullscreen = (function() {
        for (const key of [
            'exitFullscreen',
            'webkitExitFullscreen',
            'webkitCancelFullScreen',
            'mozCancelFullScreen',
            'msExitFullscreen',
        ]) {
            if (key in document) {
                return true;
            }
        }
        return false;
    }());

    const join = (e) => {
        const disableFullScreen = localStorage.getItem("disableFullScreen")
        if(canFullscreen && disableFullScreen != "disableFullScreen"){
            var doc = window.document;
            var docEl = doc.documentElement;

            var requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
            var cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

            if(!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
                requestFullScreen.call(docEl)
            }
        }

        const noSleep = new NoSleep();
        noSleep.enable().then(()=>{
            console.log("i will NEVER sleep")
        })

        document.getElementById("videoPlayer").muted = false;
        document.getElementById("audioPlayerBg").muted = false;
        document.getElementById("audioPlayerComment").muted = false;

        props.register(e)
    }

    if( memberCount < 0) return <WaitingView />
    if(eventStore==null && memberCount >= 300){
        return (
            <QuizContainer
            background={defaultBg}
            eventLogo={props.eventLogo}>
                <h2 style={{textAlign:"center", with:"100%"}}>Leider sind alle Plätze belegt. Sei beim nächsten Mal dabei!</h2>
            </QuizContainer>
        )
    }
    return(
        <QuizContainer
            background={defaultBg}
            eventLogo={props.eventLogo} >

            <div style={{textAlign: "center"}}>
                
                {eventStore == null &&
                <>
                <p >Noch {300-memberCount} {(300-memberCount) == 1 ? "freier Platz" : "freie Plätze" }</p>
                    <button
                        className={"quiz-button"}
                        style={{width:"70vw", maxHeight:"50px",height:"15vh", maxWidth:"50%"}}
                        onClick={ (e) => {
                            e.target.disabled = true ;
                            join(e);
                        }}>
                        Steig ein!
                    </button>
                </>
                }

                {eventStore != null && eventStore != "" &&
                <button
                    style={{width:"70vw", maxHeight:"50px",height:"15vh", maxWidth:"50%"}}
                    className={"quiz-button"}
                    onClick={ (e) => {
                        e.target.disabled = true;
                        join(e);
                    }}>
                    Steig wieder ein!
                </button>
                }
            </div>

        </QuizContainer>
    )
}
