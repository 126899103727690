import React from "react";
import Login1 from "views/Account/Login.js";

export default function Login({ ...rest }) {
    return (
        <div>
            <Login1 />
        </div>
    );
}
