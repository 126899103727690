import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import routes from "../routes";
import CssBaseline from '@material-ui/core/CssBaseline';
import "perfect-scrollbar/css/perfect-scrollbar.css";
import QuizContainer from "../components/Quiz/QuizContainer";
import quizBg from "../assets/img/QUIZ-Connect-Grafik_QC-Screen-BG-standard.png";
import {isChrome,isSafari ,isFirefox, browserVersion} from 'react-device-detect';
const switchRoutes = (
    <Switch>
        {routes.map((prop, key) => {
            if (prop.layout === "/playground") {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            }
            return null;
        })}
        <Redirect from="/" to="/playground/quiz" />
    </Switch>
);


export default function Player({ ...rest }) {

    let wrongVersion = false;
    let requ = "";
        if(!(isChrome || isSafari || isFirefox)){
            return (
                    <QuizContainer background={quizBg}>
                        <h2 className={"quiz-header center"}>
                            <p>Bitte verwende einen der folgenden Browser um am Quiz teilzunehmen:</p>
                            <ul style={{width:"20%",margin:"0 auto"}}>
                                <li>Chrome</li>
                                <li>Firefox</li>
                                <li>Safari</li>
                            </ul>
                        </h2>
                    </QuizContainer>
            )
        }else{
            if(isFirefox && browserVersion < 96){
                wrongVersion = true
                requ = "Firefox Version 96 oder neuer"
            }
            if(isChrome && browserVersion < 98){
                wrongVersion = true
                requ = "Chrome Version 98 oder neuer"
            }
            if(isSafari && browserVersion < 15){
                wrongVersion = true
                requ = "Safari Version 15 oder neuer"
            }

            if(wrongVersion){
                return (
                    <QuizContainer background={quizBg}>
                        <h2 className={"quiz-header center"}>
                            <p>Dein Browser verträgt ein Update. Bitte verwende folgende Version:</p>
                            <div style={{width:"20%",margin:"0 auto"}}>
                                {requ}
                            </div>
                        </h2>
                    </QuizContainer>
                )
            }
        }

    return (
        <React.Fragment>
            <div id={"playground_root"} style={{minHeight:"100%"}} onContextMenu={(e)=>{e.preventDefault();return false}}>
                <CssBaseline />
                {switchRoutes}
            </div>
            <div className={"hidden_dreck"} id={"please_rotate"}>
                <QuizContainer background={quizBg}>
                    <h2 className={"quiz-header center"}>
                        Bitte dreh dein Handy!
                    </h2>
                </QuizContainer>
            </div>
        </React.Fragment>
    );

}
