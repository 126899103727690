import QuizContainer from "../../../components/Quiz/QuizContainer";
import React, {useEffect, useState} from 'react'
import quizBg from "../../../assets/img/QUIZ-Connect-Grafik_QC-Screen-BG-standard.png";
import QRCode from 'qrcode.react'
import Countdown from "react-countdown";
import moment from "moment";

export default function QuizMasterLobby(props){

    const url =  `${window.location.protocol}//${window.location.hostname}:${window.location.port}/playground/quiz/${props.store.eventId}`

    const renderer = ({ hours, minutes, seconds, completed }) => {
        return (
            <p style={{
                margin:"0",
                fontSize:"3.5em",
                color:"white",
                fontWeight:"bold",
                marginBottom:"5vh"
            }}>
                {completed}
                {hours > 0 &&
                <span>{hours} Stunden - </span>
                }
                {minutes > 0 &&
                <span>{minutes} Minuten - </span>
                }
                {seconds} Sekunden
            </p>
        );
    };

    return(
        <QuizContainer
            background={quizBg}
            eventLogo={props.eventLogo}
        >
            <div xs={12} style={{margin:"0 auto", textAlign:"center"}}>
                {props.eventStart &&
                <Countdown date={ moment(props.eventStart).unix() * 1000  }  renderer={renderer}  onComplete={()=>{}} />
                }
            </div>
            <h2 className={"quiz-header center"} style={{textAlign:"center", fontSize:"3.5em"}}>Wähle das WLAN "Quiz-Connect",
                <br />scanne den QR-Code und öffne den Link im Internet Browser:</h2>
            <QRCode
                style={{
                    height: "25vh",
                    width: "25vh",
                    margin: "0 auto"
                }}
                renderAs={"svg"}
                level={"L"}
                value={url}
            />
        </QuizContainer>
    )
}
