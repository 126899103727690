import QuizContainer from "../../components/Quiz/QuizContainer";
import React, {useEffect, useState} from "react";
import waitingOther from "../../assets/img/Quiz-Connect-Pausenanimation-500.gif";
import gongSound from "../../assets/sounds/gong.mp3";

import img_right from "../../assets/img/QUIZ-Connect-Grafik_richtig.png"
import img_false from "../../assets/img/QUIZ-Connect-Grafik_falsch.png"
import {number} from "prop-types";

export default function ScoreView(props) {

    const score = props.properties.find(x => x.key == "score");
    const questionCount = props.properties.find(x => x.key == "questionCount");
    const questionNum = props.properties.find(x => x.key == "questionNum");
    const lastAnswer = props.properties.find(x => x.key == "lastAnswerResult");
    const [waiting, setWaiting] = useState(false)

    const hasNextQuestion = questionNum.value + 1 <= questionCount.value

    console.log("lastAnswer" , lastAnswer)
    let result = "";
    if (lastAnswer.value == "1") {
        result = img_right
    } else {
        result = img_false
    }

    useEffect(() => {

        if(hasNextQuestion == false){
            props.scoreWatched()
            return
        }

        if(props.hasMaster === false || (props.hasMaster && props.isMaster)) {
            setTimeout(()=>{
                document.getElementById("audioPlayerBg").src = gongSound;
                document.getElementById("audioPlayerBg").loop = false;
                document.getElementById("audioPlayerBg").play();
                document.getElementById("audioPlayerBg").onended = function () {};
            },1000)
        }

        setTimeout(() => {
            props.scoreWatched()
        }, 2000)
    }, [])

    return (
        <QuizContainer backgroundColor={"black"}>
            <div className={"score_view"}>
                <div className={"center"}
                     style={{textAlign: "center", margin: "auto auto", display: "flex", alignItems: "center"}} >
                    {props.isMaster == false &&
                        <img className={"_growing"} src={result}
                             style={{width: "100%", maxWidth: "200px", objectFit: "contain", margin: "10px auto"}} />
                    }
                    {props.isMaster == true &&
                        <img className={"_growing"} src={waitingOther}
                             style={{width: "100%", maxWidth: "200px", objectFit: "contain", margin: "0 auto"}} />
                    }

                </div>

                    <div className={"score-footer-wrapper"}>
                        {hasNextQuestion == false &&
                            <span className={"score-footer-text"}>Das war die letze Frage</span>
                        }
                        {hasNextQuestion == true &&
                            <span className={"score-footer-text"}>Jetzt kommt Frage {parseInt(questionNum.value) + 1} von {questionCount.value}</span>
                        }

                        {props.isMaster == false &&
                            <span className={"score-footer-text"}>Deine richtigen Antworten: {score.value}</span>
                        }
                    </div>
            </div>
        </QuizContainer>
    )
}
