import React, {useEffect, useState} from 'react'
import moment from "moment";
import QuizContainer from "../../../components/Quiz/QuizContainer";
import obdasstimmtAnimation from "../../../assets/img/Quiz-Connect-ob-das-stimmt-Animation.gif";

import ringSound from "../../../assets/sounds/ring.mp3"
import PortraitAnswerView from "./PortraitAnswerView";
import QuizMasterAnswerView from "./QuizMasterAnswerView";
import LandscapeAnswerView from "./LandscapeAnswerView";

export default function AnswerView(props) {

    const [questionText, setQuestionText] = useState("");
    const [selection, setSelection] = useState("");
    const [timerEnds, setTimerEnds] = useState(false);
    const [answerImageUrl, setAnswerImageUrl] = useState("");
    const [answerTime, setAnswerTime] = useState(null);
    const [isMaster, setIsMaster] = useState(false)
    const [hasMaster, setHasMaster] = useState(false)

    const [answerShown, setAnswerShown] = useState(Date.now())
    const [countDownTime, setCountDownTime] = useState(0)
    const [init, setInit] = useState(false)
    const answers = props.result.answers


    const timeStoreKey = props.timerEndTime + "_e";
    const startTimeStoreKey = props.timerEndTime + "_a";

    useEffect(()=>{
        const wrapper = document.getElementById("videoPlayer_wrapper");
        if(wrapper){
            wrapper.classList.add("invisible");
        }


        let t = Date.now()
        if(!localStorage.getItem(startTimeStoreKey)){
            localStorage.setItem(startTimeStoreKey, t.toString())
        }else{
             t = (parseInt(localStorage.getItem(startTimeStoreKey)))
        }

        setAnswerShown(t)
        const timerTime = props.properties.find( x=> x.key === "timerTime")

        let endTime = (moment().unix() * 1000 ) + timerTime.value * 1000;

        if(localStorage.getItem(timeStoreKey)){
            let tmp = localStorage.getItem(timeStoreKey);
            if(parseInt(tmp)){
                endTime = parseInt(tmp)
            }{
                console.log("endTime", endTime)
            }
        }else{
            localStorage.setItem(timeStoreKey, endTime.toString() )
        }

        setCountDownTime( endTime )
        setInit(true)

        const now = Date.now()
        if( (endTime - now ) <= 0 ){
            timerDidEnd();
        }

        const questionProp = props.properties.find((x) => x.key === "questionText" );
        setQuestionText(questionProp.value)

        return ()=>{
           localStorage.removeItem(timeStoreKey)
            localStorage.removeItem(startTimeStoreKey)
        }
    },[])

    useEffect(()=> {
        setIsMaster(props.isMaster)
        setHasMaster(props.hasMaster)
        const sel = props.properties.find( x=> x.key === "selection")
        if(sel){
            setSelection(sel.value)
        }
        const selTime = props.properties.find( x=> x.key === "selectionTime")
        if(selTime){
            setAnswerTime(selTime.value)
        }

    },[]);

    useEffect(()=>{

        if(props.hasMaster === false || (props.hasMaster && props.isMaster)){
            const commentSoundProp = props.properties.find((x) => x.key === "commentSound");
            const bgSoundPro = props.properties.find((x) => x.key === "bgSound" );
            const answerImageUrl = props.properties.find((x) => x.key === "answerImageUrl" );

            const bgSound = bgSoundPro.value;
            const commentSound = commentSoundProp.value;
            setAnswerImageUrl(answerImageUrl.value)

            document.getElementById("audioPlayerComment").src = commentSound;
            document.getElementById("audioPlayerBg").src = bgSound;
            document.getElementById("audioPlayerBg").loop = true;
            document.getElementById("audioPlayerBg").onended = function () {
                document.getElementById("audioPlayerBg").currentTime = 0;
            };
            document.getElementById("audioPlayerBg").play();

            let commentTime = 5;
            const timerTime = props.properties.find( x=> x.key === "timerTime")
            if(timerTime && timerTime.value){

                let commentTimeRaw = parseInt(timerTime.value)

                if(commentTimeRaw) {
                    commentTime = commentTimeRaw - 5;
                }

            }

            setTimeout(function(){
                document.getElementById("audioPlayerComment").play();
            } , commentTime * 1000 )

        }
    },[])


    const timerDidEnd = ()=>{

        if(hasMaster === false || (hasMaster && isMaster)) {
            document.getElementById("audioPlayerBg").src = ringSound;
            document.getElementById("audioPlayerBg").loop = false;
            document.getElementById("audioPlayerBg").play();
            document.getElementById("audioPlayerBg").onended = function () {};
        }
        setTimerEnds(true);
        setTimeout(props.timerEnded, 2000)
    }

    const answerClicked = (e,aId) => {

        if(!selection){
            setSelection(aId)
            const time = Date.now() - answerShown;
            setAnswerTime(time)
            props.onAnswerClicked( aId,time);
        }else {
            console.log("answer", aId)
        }
    };

    let content = "";
    if(timerEnds){
        if (props.hasMaster) {
            content= (
                <QuizContainer backgroundColor={"black"} >
                </QuizContainer>
            )
        }else{
            content= (
                <QuizContainer backgroundColor={"black"} >
                    <img src={obdasstimmtAnimation} style={{maxWidth:"300px",width:"30%" , textAlign:"center", margin:"0 auto"}}/>
                </QuizContainer>
            )
        }

    }
    else if(timerEnds == false) {
        if (props.hasMaster && props.isMaster) {
            content = (
                <QuizMasterAnswerView
                    answerImageUrl={answerImageUrl}
                    questionText={questionText}
                    answerTime={answerTime}
                    timerDidEnd={timerDidEnd}
                    timerEndTime={countDownTime} />
            )
        }else if(props.hasMaster && props.isMaster == false){
            content = (
                <PortraitAnswerView
                    hasMaster={ props.hasMaster}
                    isMaster = {props.isMaster}
                    answers={answers}
                    answerImageUrl={answerImageUrl}
                    questionText={questionText}
                    selection={selection}
                    answerTime={answerTime}
                    answerClicked={answerClicked}
                    timerDidEnd={timerDidEnd}
                    timerEndTime={countDownTime}
                />
            );
        }else if(props.hasMaster == false){
            content = (
                <LandscapeAnswerView
                    hasMaster={ props.hasMaster}
                    isMaster = {props.isMaster}
                    answers={answers}
                    answerImageUrl={answerImageUrl}
                    questionText={questionText}
                    selection={selection}
                    answerTime={answerTime}
                    answerClicked={answerClicked}
                    timerDidEnd={timerDidEnd}
                    timerEndTime={countDownTime}
                />
            );
        }
    }
    if(!init) return <div></div>

    return (
        <React.Fragment>
            {content}
        </React.Fragment>
    )
}
