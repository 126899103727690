import React from 'react'
import "video-react/dist/video-react.css";
import QuizContainer from "../../components/Quiz/QuizContainer";
import GridItem from "../../components/Grid/GridItem";

import brightBG from "../../assets/img/QC-Screen-gelb-OHNE-Quiz_QC-Screen-BG-Quiz.png";
import animation from "../../assets/img/Quiz-Connect-Ladeanimation.gif";

export default function WaitingView(props) {
    return (
        <QuizContainer
            background={brightBG} >
            <GridItem xs={12} className={"center"} style={{textAlign:"center",margin:"auto auto",display: "flex",alignItems: "center"}}>
                <img src={animation} style={{width:"100%", maxWidth:"600px"}} />
            </GridItem>
        </QuizContainer>
    );
}
