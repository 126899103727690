import React, {useState} from 'react'

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import {TextField, Container} from "@material-ui/core/";
import Button from "components/CustomButtons/Button.js";
import CardFooter from "../../components/Card/CardFooter";
import Redirect from "react-router-dom/Redirect";
import fetcher from "services/Fetcher.js"

export default function Login(props) {
    
    const [login, setLogin] = useState({
        email:"",
        password:""
    })

    const [redirectURL, redirect] = useState("")
    const [error, setError] = useState("")
    
    const doLogin = function(){
        const url = "authentication/tokens";
        let data = JSON.stringify(login);
        
        fetcher(url, {
            method: "POST",
            body: data,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then(resp => resp.json())
            .then( (loginResult) => {
                if(loginResult.success){
                    localStorage.setItem('token', loginResult.token );
                    redirect("/admin/events")
                }else{
                    setError("Login fehlgeschlagen");
                }
            } )
    }

    const inputChanged = (e)=>{
        setLogin({
            ...login,
            [e.target.name] : e.target.value
        })
    }

    if(redirectURL !== ""){
        return(
            <Redirect to={redirectURL} />
        )
    }

    return (
        <Container maxWidth="sm">
            <Card>
                <CardHeader color={"primary"}>
                    <h4>Login:</h4>
                </CardHeader>
                <CardBody>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            {error}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <TextField label="E-Mail" type="mail" fullWidth name="email" value={login.email} onChange={ (e)=> inputChanged(e)} />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <TextField label="Passwort" type={"password"} name="password" fullWidth value={login.password} onChange={ (e)=> inputChanged(e)} />
                    </GridItem>
                    </GridContainer>
                </CardBody>
                <CardFooter>
                    <Button onClick={doLogin} color={"primary"}>Login</Button>
                </CardFooter>
            </Card>
        </Container>
    );
}
