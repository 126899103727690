import QuizContainer from "../../../components/Quiz/QuizContainer";
import React from "react";
import bg from "../../../assets/img/QUIZ-Connect-Grafik_QC-Screen-BG-Fehler.png";

export default function QuizMemberLandscapeScore(props){
    return(
        <QuizContainer background={bg} >
            <div id="finisher_landscape" className="center quizMasterScore winner_warpper" >
                {props.result &&
                <div className={"winner-list_landscape"}>
                    {props.result.winners.length >= 2 &&
                    <div className={"winner_landscape winner_3_landscape"} >
                        <img src={props.result.winners[1].avatar} alt={"avatar"}  className={"winner-avatar_landscape"} />
                        <div className={"info_winner_box info_winner_box_2_landscape"}>
                            <span className={"winner-placement_landscape"} >2.</span>
                            <span className={"winner-name_landscape"}  >{props.result.winners[1].name}</span>
                            <span className={"winner-score_landscape"}  >Mit {props.result.winners[1].score} richtigen Antworten</span>
                            <span className={"winner-avarage_landscape"}  >{props.result.winners[1].averageTime / 1000} Sek. &#x00D8;</span>
                        </div>
                    </div>
                    }

                    {props.result.winners.length >= 1 &&
                    <div className={"winner_landscape winner_1_landscape"} >
                        <img src={props.result.winners[0].avatar} alt={"avatar"} className={"winner-avatar_landscape"}/>
                        <div className={"info_winner_box info_winner_box_1"}>
                            <span className={"winner-placement_landscape"}>1.</span>
                            <span className={"winner-name_landscape"} >{props.result.winners[0].name}</span>
                            <span className={"winner-score_landscape"} >Mit {props.result.winners[0].score} richtigen Antworten</span>
                            <span className={"winner-avarage_landscape"} >{props.result.winners[0].averageTime / 1000} Sek. &#x00D8;</span>
                        </div>
                    </div>
                    }
                    {props.result.winners.length >= 3 &&
                    <div className={"winner_landscape winner_3_landscape"} >
                        <img src={props.result.winners[2].avatar} alt={"avatar"} className={"winner-avatar_landscape"}/>
                        <div className={"info_winner_box info_winner_box_3"}>
                            <span className={"winner-placement_landscape"} >3.</span>
                            <span className={"winner-name_landscape"} >{props.result.winners[2].name}</span>
                            <span className={"winner-score_landscape"} >Mit {props.result.winners[2].score} richtigen Antworten</span>
                            <span className={"winner-avarage_landscape"} >{props.result.winners[2].averageTime / 1000} Sek. &#x00D8;</span>
                        </div>
                    </div>
                    }
                </div>
                }

                <div style={{margin:"1vh auto", textAlign:"center"}}>
                    <span>Dein Platz: {props.result.placement}</span><br />
                    <span>Richtige Antworten: {props.result.privateScore} ({props.result.privateAverageTime / 1000} Sek. &#x00D8;)</span>
                </div>
            </div>
        </QuizContainer>
    )
}
