import React from "react";

export default function renderer ({ isMaster, hasMaster, hours, minutes, seconds, customStyles }){
    let counterStyles = {
        color:"#fff",
        width:"50px",
        lineHeight:"50px",
        backgroundColor:"#c70808",
        textAlign:"center",
        borderRadius: "80px",
        fontSize:"2em",
        fontWeight:"bold",
        marginTop:"25px",
        fontFamily:"Quicksand",
        margin:"0 auto",
        verticalAlign:"middle"
    }
    if (hasMaster === false || (hasMaster && isMaster)) {
        counterStyles = {
            ...counterStyles,
            fontSize: "5em",
            lineHeight:"100px",
            width:"100px",
        }
    }
    const styles = {
        ...counterStyles,
        ...customStyles
    }
    let total = seconds + (minutes * 60) + (hours * 60 * 60 )
    return <div style={styles}>{total}</div>;
}
