import React, {useEffect, useState} from 'react'
import "video-react/dist/video-react.css";
import ActionAtMasterView from "./QuizMaster/ActionAtMasterView";
import VideoStream from "../../services/VideoStream";

export default function OutroView(props) {
    const [videoUrl, setVideoUrl] = useState("")

    useEffect(()=>{
        if(props.hasMaster === false || (props.hasMaster && props.isMaster)){
            const url = props.properties.find( val => val.key === "videoUrl" )
            if(url){
                if(url.value == ""){
                    console.log("outro endedn cause no url" , url)
                    props.videoEnded();
                }else{
                    setVideoUrl(url.value)
                }
            }
        }

    },[]);

    if(props.hasMaster && !props.isMaster){
        return (
            <ActionAtMasterView />
        );
    }else{
        if(videoUrl == "") return <div style={{height:"100vh",width:"100vh",backgroundColor:"black"}}></div>
        return (
            <div style={{height:"100vh",width:"100%",backgroundColor:"black"}}>
                <VideoStream
                    url={videoUrl}
                    videoEnded={props.videoEnded}
                    videoTime={props.videoTime}
                    sendCurrentVideoQuestionTime={ ()=>{} }
                />
            </div>
        );
    }
}
