import QuizContainer from "../../../components/Quiz/QuizContainer";
import Countdown from "react-countdown";
import renderer from "./AnswerViewCountdownRenderer";
import React from "react";

export default function LandscapeAnswerView(props) {
    const alphaNum = [];
    alphaNum[0] = "A";
    alphaNum[1] = "B";
    alphaNum[2] = "C";
    alphaNum[3] = "D";
    alphaNum[4] = "E";
    alphaNum[5] = "F";

    const isMaster = props.isMaster
    const hasMaster = props.hasMaster
    return (
        <QuizContainer
            backgroundColor={"black"}
            theme={props.answerImageUrl} >

            <div style={{textAlign: "center"}}>
                <p className={"questionText"}>{props.questionText}</p>
            </div>
            <div style={{textAlign: "center"}}>
                <div className={"grid2x2"}>
                    {props.answers.map((value, index) => {
                        let aTime = "";
                        let className;

                        if (props.selection === value.id) {
                            if (props.answerTime > 0) {
                                aTime = " (" + props.answerTime / 1000 + " Sek.)"
                            }
                            className = "answer-button answer-button-selected"
                        } else {
                            className = "answer-button answer-button-not-selected"
                        }
                        return (
                            <div
                                style={{borderRadius:"30px"}}
                                className={className}
                                onClick={(e) => {
                                    props.answerClicked("answerBtn_" + index, value.id)
                                }}
                                key={"answer_" + index}>
                                <div style={{fontSize:"1.2em", padding:"0 20px"}} className={"answer-button-prefix"}><span>{alphaNum[index]}</span></div>
                                <a
                                    id={"answerBtn_" + index}
                                    type={"button"} >

                                    {value.text}
                                    <span className={"right"} >{aTime}</span>
                                </a>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div>
                <Countdown
                    onComplete={props.timerDidEnd}
                    date={props.timerEndTime}
                    renderer={({hours, minutes, seconds, completed}) => renderer({
                        hasMaster,
                        isMaster,
                        hours,
                        minutes,
                        seconds,
                        customStyles:{
                            lineHeight:"80px",
                            width:"80px",
                            fontSize: "2.4em"
                        }
                    })}/>
            </div>
        </QuizContainer>
    )
}
